import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { env, getPath } from 'configs/EnvironmentConfig';
import { ClientsState } from './definitions';
import { Client, GroupEntity, Work, Workday } from 'interfaces';

const RESOURCES = {
  WORKS: getPath('works', 'works'),
  GROUP_ENTITIES: getPath('groupEntities', 'groupEntities'),
  SEARCH_CLIENT: getPath('client', 'clientHandler'),
  WORKDAY: getPath('workday', 'workday'),
};

export const fetchClient = createAsyncThunk(
  'client/fetchClient',
  (id: string) =>
    axios
      .get<Client[]>(`${env?.API_ENDPOINT_URL}/${RESOURCES.SEARCH_CLIENT}`, {
        params: { id },
      })
      .then((resp) => {
        return resp?.data?.[0];
      })
);

export const fetchGroupEntities = createAsyncThunk(
  'clients/fetchGroupEntities',
  (_, { getState }) => {
    const {
      clients: { groupEntities },
    } = getState() as {
      clients: ClientsState;
    };

    if (groupEntities.data.length) {
      return groupEntities.data;
    }

    return axios
      .get<GroupEntity[]>(
        `${env?.API_ENDPOINT_URL}/${RESOURCES.GROUP_ENTITIES}`
      )
      .then((resp) => resp.data);
  }
);

export const fetchWorks = createAsyncThunk(
  'client/fetchWorks',
  (clientId: string) =>
    axios
      .get<Work[]>(`${env?.API_ENDPOINT_URL}/${RESOURCES.WORKS}`, {
        params: { clientId },
      })
      .then((resp) => resp.data)
);

export const fetchWorkday = createAsyncThunk(
  'client/fetchWorkday',
  (client: string) =>
    axios
      .get<Workday[]>(`${env?.API_ENDPOINT_URL}/${RESOURCES.WORKDAY}`, {
        params: { client },
      })
      .then((resp) => resp.data)
);
