import React from 'react';
import { connect } from 'react-redux';
import { Card, Form, Spin, Tabs } from 'antd';
import { Dispatch, bindActionCreators } from 'redux';

import { Client } from 'interfaces';
import { ClientState, ClientsState } from 'store/interfaces';

import * as httpActions from 'store/httpActions';
import * as CommercialTab from './tabs';

import { parseClient } from './utils';

interface Props {
  clientState: ClientState;
  dispatchFetchAllData: () => void;
}

const Commercial = ({ clientState, dispatchFetchAllData }: Props) => {
  const [form] = Form.useForm<Client>();

  const tabItems = [
    {
      label: 'Información comercial',
      key: 'Información comercial',
      children: <CommercialTab.CommercialInformation />,
    },
    {
      label: 'Servicios',
      key: 'Servicios',
      children: <CommercialTab.Services />,
    },
    {
      label: 'Documentación',
      key: 'Documentación',
      children: <CommercialTab.Documentation />,
    },
    {
      label: 'Aceptación',
      key: 'Aceptación',
      children: <CommercialTab.Acceptance />,
    },
    {
      label: 'Operación',
      key: 'Operación',
      children: <CommercialTab.Operation />,
    },
  ];

  React.useEffect(() => {
    if (!clientState.data) return;

    form.setFieldsValue(parseClient(clientState.data));
  }, [clientState.data, form]);

  React.useEffect(() => {
    dispatchFetchAllData();
  }, [dispatchFetchAllData]);

  return (
    <Card>
      <Spin spinning={clientState.isLoading}>
        <Form form={form} layout="vertical">
          <Tabs defaultActiveKey="Información comercial" items={tabItems} />
        </Form>
      </Spin>
    </Card>
  );
};

const mapStateToProps = (state: {
  clients: ClientsState;
}): {
  clientState: ClientState;
} => {
  return {
    clientState: state.clients.client,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    ...bindActionCreators(
      {
        dispatchFetchAllData: () => {
          return () => {
            dispatch(httpActions.fetchHolidays());
            dispatch(httpActions.fetchModifierTypes());
          };
        },
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Commercial);
