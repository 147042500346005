import { combineReducers } from 'redux';
import theme from './slices/themeSlice';
import auth from './slices/authSlice';
import clients from './slices/clients';
import payingCompanies from './slices/payingCompaniesSlice';
import statesAndMunicipality from './slices/statesAndMunicipalitySlice';
import levita from './slices/levitaSlice';
import catalogs from './slices/catalogs';

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    clients,
    payingCompanies,
    statesAndMunicipality,
    levita,
    catalogs,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
