import React from 'react';
import { Card, Form, Radio, Select, Typography, notification } from 'antd';
import axios from 'axios';

import { env, getPath } from 'configs/EnvironmentConfig';
import { BankAccount, Payroll } from 'interfaces';

const { Title } = Typography;

const RESOURCES = {
  BANK_ACCOUNTS: getPath('bankAccounts', 'bankAccounts'),
};

export const getBankAccounts = (society: string) =>
  axios
    .post<BankAccount[]>(
      `${env?.API_ENDPOINT_URL}/${RESOURCES.BANK_ACCOUNTS}`,
      {
        societies: [society],
      }
    )
    .then((resp) => resp.data);

interface Props {
  selectedPayroll: undefined | Payroll;
  onChangeSelectedPayroll: (key: string, value: any) => void;
}

const Banks = ({ selectedPayroll }: Props) => {
  const [bankAccounts, setBankAccounts] = React.useState<BankAccount[]>([]);
  const [isLoadingBankAccounts, setIsLoadingBankAccounts] =
    React.useState(false);

  const fetchBankAccounts = React.useCallback(async () => {
    if (!selectedPayroll?.dispersing_company) return;

    setIsLoadingBankAccounts(true);
    try {
      const resp = await getBankAccounts(selectedPayroll.dispersing_company);

      setBankAccounts(resp);
    } catch (error: any) {
      notification.error({
        message: 'Error al obtener BankAccounts',
        description: error?.message || 'Error desconocido',
      });
    } finally {
      setIsLoadingBankAccounts(false);
    }
  }, [selectedPayroll?.dispersing_company]);

  React.useEffect(() => {
    fetchBankAccounts();
  }, [fetchBankAccounts]);

  return (
    <Card>
      <Title level={3}>Bancos</Title>
      <Form.Item
        label="Cuenta de Banco donde deposita el cliente"
        name={
          isLoadingBankAccounts ? undefined : 'customer_deposit_bank_account'
        }
      >
        <Select
          options={bankAccounts.map((i) => ({
            label: `${i?.bank} ( Cuenta: ${i?.account_number} )`,
            value: i._id,
          }))}
          loading={isLoadingBankAccounts}
          disabled={isLoadingBankAccounts}
          placeholder={
            isLoadingBankAccounts
              ? 'Cargando cuentas de banco...'
              : 'Selecciona cuenta de banco'
          }
        />
      </Form.Item>
      <Form.Item label="Es interbancaria" name="is_interbank">
        <Radio.Group>
          <Radio value={true}>Sí</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </Form.Item>
    </Card>
  );
};

export default Banks;
