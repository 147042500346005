export const rules = {
  businessName: [
    { required: true, message: 'Ingresa la razon social del cliente' },
    { warningOnly: true },
  ],
  tradeName: [
    { required: true, message: 'Ingresa el nombre comercial' },
    { warningOnly: true },
  ],
  code: [
    { required: true, message: 'Ingresa el codigo de referencia' },
    { warningOnly: true },
  ],
  industry: [
    { required: true, message: 'Ingresa el giro de la empresa' },
    { warningOnly: true },
  ],
  workAddress: [
    { required: true, message: 'Ingresa la direccion' },
    { warningOnly: true },
  ],
  zipCode: [
    { required: true, message: 'Ingresa el codigo postal' },
    { warningOnly: true },
  ],
  rfc: [{ required: true, message: 'Ingresa el RFC' }, { warningOnly: true }],
  legalRepresentative: [
    { required: true, message: 'Ingresa el representante legal' },
    { warningOnly: true },
  ],
  employerRegistration: [
    { required: true, message: 'Ingresa el registro patronal' },
    { warningOnly: true },
  ],
  serviceType: [
    { required: true, message: 'Ingresa tipo de servicio' },
    { warningOnly: true },
  ],
  formulaType: [
    { required: true, message: 'Ingresa el tipo de formula' },
    { warningOnly: true },
  ],
  frequencies: [
    { required: true, message: 'Ingresa los periodos' },
    { warningOnly: true },
  ],
  frequencyTypes: [
    { required: true, message: 'Ingresa las frecuencias' },
    { warningOnly: true },
  ],
  payingCompanies: [
    { required: true, message: 'Ingresa las empresas' },
    { warningOnly: true },
  ],
  plaza: [
    { required: true, message: 'Ingresa la plaza' },
    { warningOnly: true },
  ],
  risks: [
    { required: true, message: 'Ingresa los riesgos' },
    { warningOnly: true },
  ],
  seller: [
    { required: true, message: 'Ingresa el vendedor' },
    { warningOnly: true },
  ],
  sellerComission: [
    { required: true, message: 'Ingresa el % de comision negociada' },
    { warningOnly: true },
  ],
  bankAccountsToDepositPayroll: [
    {
      required: true,
      message: 'Ingresa las cuentas donde el cliente pagara la nomina',
    },
    { warningOnly: true },
  ],
  bankAccountsForPayrollDispersal: [
    {
      required: true,
      message: 'Ingresa las cuentas que se utilizaran para dispersar',
    },
    { warningOnly: true },
  ],
  employeeBanks: [
    {
      required: true,
      message: 'Ingresa los bancos que manjean los colaboradores',
    },
    { warningOnly: true },
  ],
  employeeCardsBank: [
    {
      required: true,
      message: 'Ingresa los bancos si se proporcionaran tarjetas',
    },
    { warningOnly: true },
  ],
  bankMigration: [
    { required: true, message: 'Ingresa si se realizara migracion de banco' },
    { warningOnly: true },
  ],
  numberOfEmployees: [
    { required: true, message: 'Ingresa el numero de empleados' },
    { warningOnly: true },
  ],
  potentialEmployees: [
    { required: true, message: 'Ingresa el potencial de crecimiento' },
    { warningOnly: true },
  ],
  dischargeDate: [
    {
      required: true,
      message: 'Ingresa la fecha de baja de la empresa anterior',
    },
    { warningOnly: true },
  ],
  chargeDate: [
    {
      required: true,
      message: 'Ingresa la fecha de alta en el sistema INTELA',
    },
    { warningOnly: true },
  ],
  IMSSChargeDate: [
    { required: true, message: 'Ingresa la fecha de alta en el IMSS' },
    { warningOnly: true },
  ],
  employeesTransitionCommitmentDate: [
    {
      required: true,
      message: 'Ingresa la fecha de compromiso para transicion de empleados',
    },
    { warningOnly: true },
  ],
  collectingContractsCommitmentDate: [
    {
      required: true,
      message: 'Ingresa la fecha compromiso de recoleccion de contratos',
    },
    { warningOnly: true },
  ],
  commitmentDates: [
    { required: true, message: 'Ingresa el envio de contratos' },
    { warningOnly: true },
  ],
  services: [
    { required: true, message: 'Ingresa los servicios contratados' },
    { warningOnly: true },
  ],
  coverageStartDate: [
    { required: true, message: 'Ingresa la fecha de cobertura' },
    { warningOnly: true },
  ],
  chargeStartDate: [
    { required: true, message: 'Ingresa la fecha de inicio de cobro' },
    { warningOnly: true },
  ],
  contract: [
    { required: true, message: 'Ingresa el contrato de servicio firmado' },
    { warningOnly: true },
  ],
  caseFile: [
    { required: true, message: 'Ingresa la documentacion de la empresa' },
    { warningOnly: true },
  ],
  employeeContracts: [
    {
      required: true,
      message: 'Ingresa el contrato individual de contratos firmados',
    },
    { warningOnly: true },
  ],
  collaboratorsDocumentation: [
    { required: true, message: 'Ingresa la documentacion de colaboradores' },
    { warningOnly: true },
  ],
  eba: [
    { required: true, message: 'Ingresa el eba proporcionado' },
    { warningOnly: true },
  ],
  ema: [
    { required: true, message: 'Ingresa el ema proporcionado' },
    { warningOnly: true },
  ],
  documentsDeliveryCommitmentDate: [
    {
      required: true,
      message:
        'Ingresa la fecha compromiso de entrega de expedientes completos',
    },
    { warningOnly: true },
  ],
  registrationWork: [
    { required: true, message: 'Ingresa el registro de la obra proporcionado' },
    { warningOnly: true },
  ],
  operativeContactName: [
    { required: true, message: 'Ingresa el nombre del contacto operativo' },
    { warningOnly: true },
  ],
  operativeContactEmail: [
    { required: true, message: 'Ingresa el email del contacto operativo' },
    { warningOnly: true },
  ],
  operativeContactAddress: [
    { required: true, message: 'Ingresa la direccion del contacto operativo' },
    { warningOnly: true },
  ],
  oprtativeContactPhone: [
    { required: true, message: 'Ingresa el telefono del contacto operativo' },
    { warningOnly: true },
  ],
  operativeContactSchedule: [
    { required: true, message: 'Ingresa el horario de atencion' },
    { warningOnly: true },
  ],
  isnStates: [
    { required: true, message: 'Ingresa los estados de ISN' },
    { warningOnly: true },
  ],
  isnRetention: [
    { required: true, message: 'Ingresa los % de retencion' },
    { warningOnly: true },
  ],
  supportWithPaperwork: [
    { required: true, message: 'Ingresa el apoyo con tramite de ISN' },
    { warningOnly: true },
  ],
  personWhoSupports: [
    { required: true, message: 'Ingresa quien apoyara el tramite' },
    { warningOnly: true },
  ],
  groupEntity: [
    { required: true, message: 'Ingresa el grupo de cliente' },
    { warningOnly: true },
  ],
  lowestWagePermitted: [
    { required: true, message: 'Ingresa el salario diario minimo autorizado' },
    { warningOnly: true },
  ],
  isBorderArea: [
    { required: true, message: 'Ingresa la zona economica' },
    { warningOnly: true },
  ],
  dateFirstDisperssion: [
    { required: true, message: 'Ingresa la fecha de primera dispersion' },
    { warningOnly: true },
  ],
  periodCutOffDay: [
    { required: true, message: 'Ingresa el corte de periodo' },
    { warningOnly: true },
  ],
  isnCommitmentDate: [
    {
      required: true,
      message: 'Ingresa la fecha compromiso para realizar el ISN',
    },
    { warningOnly: true },
  ],
  observations: [
    { required: true, message: 'Ingresa acuerdos especiales' },
    { warningOnly: true },
  ],
  whoAbsorbsISR: [
    { required: true, message: 'Ingresa quien absorbe el ISR' },
    { warningOnly: true },
  ],
  nom035Agreement: [
    { required: true, message: 'Ingresa los acuerdos para encuestas NOM035' },
    { warningOnly: true },
  ],
  fiscalRegime: [
    { required: true, message: 'Ingresa el regimen fiscal' },
    { warningOnly: true },
  ],
  useCFDI: [
    { required: true, message: 'Ingresa el uso del CFDI' },
    { warningOnly: true },
  ],
  paymentMethod: [
    { required: true, message: 'Ingresa el metodo de pago' },
    { warningOnly: true },
  ],
  paymentMethodType: [
    { required: true, message: 'Ingresa la forma de pago' },
    { warningOnly: true },
  ],
  billingConcept: [
    { required: true, message: 'Ingresa el concepto de facturacion' },
    { warningOnly: true },
  ],
  satConceptKey: [
    { required: true, message: 'Ingresa la clave de concepto' },
    { warningOnly: true },
  ],
};

export const fieldsTranslation = {
  businessName: 'Razon social del cliente',
  tradeName: 'Nombre comercial',
  code: 'Codigo de referencia',
  industry: 'Giro de la empresa',
  workAddress: 'Direccion',
  zipCode: 'Codigo postal',
  rfc: 'RFC',
  legalRepresentative: 'Representante legal',
  employerRegistration: 'Registro patronal',
  serviceType: 'Tipo de servicio',
  formulaType: 'Tipo de formula',
  frequencies: 'Periodos',
  frequencyTypes: 'Frecuencias',
  payingCompanies: 'Empresas',
  plaza: 'Plaza',
  risks: 'Riesgos',
  seller: 'Vendedor',
  sellerComission: '% de comision negociada',
  bankAccountsToDepositPayroll: 'Cuentas pagara la nomina',
  bankAccountsForPayrollDispersal: 'Cuentas donde se dispersara',
  employeeBanks: 'Bancos colaboradores',
  employeeCardsBank: 'Bancos para tarjetas',
  bankMigration: 'Migracion de banco',
  numberOfEmployees: 'Numero de empleados',
  potentialEmployees: 'Potencial de crecimiento',
  dischargeDate: 'Fecha baja empresa anterior',
  chargeDate: 'Fecha alta en INTELA',
  IMSSChargeDate: 'Fecha alta en IMSS',
  employeesTransitionCommitmentDate: 'Fecha compromiso transision empleados',
  collectingContractsCommitmentDate:
    'Fecha compromiso recoleccion de contratos',
  commitmentDates: 'Envio de contratos',
  services: 'Servicios contratados',
  coverageStartDate: 'Fecha de cobertura',
  chargeStartDate: 'Fecha inicio de cobro',
  contract: 'Contrato de servicio firmado',
  caseFile: 'Documentacion de la empresa',
  employeeContracts: 'Contrato individual',
  collaboratorsDocumentation: 'Documentacion de los colaboradores',
  eba: 'EBA',
  ema: 'EMA',
  documentsDeliveryCommitmentDate: 'Fecha compromiso expedientes completos',
  registrationWork: 'Registro de la obra',
  operativeContactName: 'Nombre contacto operativo',
  operativeContactEmail: 'Email contacto operativo',
  operativeContactAddress: 'Direccion contracto operativo',
  oprtativeContactPhone: 'Telefono contacto operativo',
  operativeContactSchedule: 'Horario de atencion contracto operativo',
  isnStates: 'Estados de ISN',
  isnRetention: '% de retencion de ISN',
  supportWithPaperwork: 'Apoyo con tramite ISN',
  personWhoSupports: 'Apoyo en tramite',
  groupEntity: 'Grupo de cliente',
  lowestWagePermitted: 'Fecha primera disperion',
  isBorderArea: 'Zona economica',
  dateFirstDisperssion: 'Fecha primera dispersion',
  periodCutOffDay: 'Corte de periodo',
  isnCommitmentDate: 'Fecha compromiso para realizar ISN',
  whoAbsorbsISR: 'Quien absorbe el ISR',
  nom035Agreement: 'Acuerdos para encuestras NOM035',
  fiscalRegime: 'Regimen fiscal',
  useCFDI: 'Uso de CFDI',
  paymentMethod: 'Metodo de pago',
  paymentMethodType: 'Forma de pago',
  billingConcept: 'Concepto de facturacion',
  satConceptKey: 'Clave de concepto',
};
