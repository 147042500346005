import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Table,
} from 'antd';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { EditOutlined, DeleteOutlined, CheckOutlined } from '@ant-design/icons';

import {
  LevitaState,
  ModalActions,
  ModalLevita,
  closeModal,
  openModal,
} from 'store/slices/levitaSlice';
import { CatalogsState } from 'store/slices/catalogs';
import { Concept, Invoicing } from 'interfaces';
import { ColumnsType } from 'antd/es/table/interface';

export const ADD_BILLING_MODAL = 'ADD_BILLING_MODAL';
const INVOICE_CONCEPTS_ERROR =
  'Revise los conceptos de la factura, mínimo debe haber un concepto y la suma total de los porcentajes debe ser ';

const getTotalInvoiceConcepts = (invoiceConcepts: Concept[]): number => {
  return invoiceConcepts.reduce((acc, item) => acc + item.percentage, 0);
};

const isModalOpen = (modalState: ModalLevita) =>
  modalState.open && modalState.name === ADD_BILLING_MODAL;

interface Props {
  modal: ModalActions;
  concepts: Concept[];
  modalState: ModalLevita;
  catalogsState: CatalogsState;
  editingInvoice: Invoicing | undefined;
  callback: (invoicing: Invoicing) => void;
}

const AddBilling = ({
  modal,
  concepts,
  callback,
  modalState,
  catalogsState,
  editingInvoice,
}: Props) => {
  const [form] = Form.useForm<Invoicing>();
  const totalInvoicePercentage = Form.useWatch('percentage', form);

  const [isLoading, setIsLoading] = React.useState(false);
  const [invoiceConcepts, setInvoiceConcepts] = React.useState<Concept[]>([]);
  const [errorInvoiceConcepts, setErrorInvoiceConcepts] = React.useState('');
  const [editInvoiceConceptId, setEditInvoiceConceptId] = React.useState('');

  const { cfdis, paymentMethods } = catalogsState;

  const clearForm = () => {
    modal.close();

    setEditInvoiceConceptId('');
    form.resetFields();
  };

  const handleSubmit = async () => {
    setErrorInvoiceConcepts('');

    const totalInvoiceConcepts = getTotalInvoiceConcepts(invoiceConcepts);
    if (totalInvoiceConcepts !== totalInvoicePercentage) {
      setErrorInvoiceConcepts(INVOICE_CONCEPTS_ERROR);
      return;
    }

    setIsLoading(true);

    try {
      //fake fetch action
      const formFields = await form.validateFields();
      await new Promise((resolve) => setTimeout(resolve, 2000));

      const invoicing: Invoicing = {
        id: formFields.id ? formFields.id : uuidv4(),
        name: formFields.name,
        percentage: formFields.percentage,
        payment_method: formFields.payment_method,
        use_cfdi: formFields.use_cfdi,
        concepts: invoiceConcepts,
      };

      callback(invoicing);
      clearForm();
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    clearForm();
  };

  const handleEditInvoiceConcept = (id: string) => setEditInvoiceConceptId(id);

  const handleUpdateInvoiceConcept = (invoiceConcept: Concept) => {
    const invoiceConceptsUpdated = invoiceConcepts.map((i) =>
      i.id === invoiceConcept.id ? invoiceConcept : i
    );

    setInvoiceConcepts(invoiceConceptsUpdated);
  };

  const handleSaveInvoiceConcept = () => setEditInvoiceConceptId('');

  const handleRemoveConcept = (id: string) => {
    setInvoiceConcepts(invoiceConcepts.filter((i) => i.id !== id));
  };

  const columns: ColumnsType<Concept> = [
    {
      key: 'Acciones',
      title: 'Acciones',
      render: (_, record) => {
        return (
          <Space>
            {editInvoiceConceptId === record.id ? (
              <Button
                onClick={handleSaveInvoiceConcept}
                title="Guardar porcentaje"
                type="text"
                icon={<CheckOutlined />}
              />
            ) : (
              <Button
                onClick={() => handleEditInvoiceConcept(record.id)}
                title="Editar porcentaje"
                type="text"
                icon={<EditOutlined />}
              />
            )}
            <Button
              onClick={() => handleRemoveConcept(record.id)}
              type="text"
              icon={<DeleteOutlined />}
            />
          </Space>
        );
      },
    },
    {
      key: 'Código de concepto',
      title: 'Código de concepto',
      dataIndex: 'concept_key',
    },
    {
      key: 'Descripción de concepto',
      title: 'Descripción de concepto',
      dataIndex: 'description',
    },
    {
      key: 'Giro de servicio de concepto',
      title: 'Giro de servicio de concepto',
      dataIndex: 'service_type',
    },
    {
      key: 'Porcentaje del total',
      title: 'Porcentaje del total',
      dataIndex: 'percentage',
      render: (_, record) =>
        editInvoiceConceptId === record.id ? (
          <InputNumber
            min={0}
            max={100}
            value={record.percentage}
            controls={false}
            onChange={(value: number | null) => {
              const invoiceConceptUpdated: Concept = {
                ...record,
                percentage: value === null ? 0 : value,
              };

              handleUpdateInvoiceConcept(invoiceConceptUpdated);
            }}
          />
        ) : (
          record.percentage
        ),
    },
  ];

  React.useEffect(() => {
    if (!modalState.open) return;

    setInvoiceConcepts(concepts);
  }, [concepts, modalState.open]);

  React.useEffect(() => {
    if (!editingInvoice) return;
    if (!modalState.open) return;

    form.setFieldsValue(editingInvoice);
  }, [editingInvoice, form, modalState.open]);

  return (
    <Modal
      open={isModalOpen(modalState)}
      okText={isLoading ? 'Guardando' : 'Guardar'}
      title={editingInvoice ? 'Editar Factura' : 'Agregar Factura'}
      onOk={handleSubmit}
      onCancel={handleCloseModal}
      confirmLoading={isLoading}
      width={1000}
      destroyOnClose
      forceRender
    >
      <Form
        layout="vertical"
        form={form}
        disabled={isLoading}
        initialValues={{ name: 'Factura ', percentage: 100 }}
      >
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Row gutter={16}>
          <Col className="gutter-row" span={16}>
            <Form.Item
              label="Nombre factura"
              name="name"
              rules={[
                { required: true, message: 'Ingresa el nombre de la factura' },
                { whitespace: true, message: 'El nombre es obligatorio' },
              ]}
            >
              <Input placeholder="Ingresa el nombre de la factura" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              label="Porcentaje del total factura"
              name="percentage"
              rules={[{ required: true, message: 'Ingresa el porcentaje' }]}
            >
              <InputNumber
                min={0}
                max={100}
                controls={false}
                className="w-100"
                addonAfter="%"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="Uso CFDI"
              name="use_cfdi"
              rules={[{ required: true, message: 'Selecciona Uso CFDI' }]}
            >
              <Select
                options={cfdis.data.map((i) => ({
                  label: `(${i.c_UsoCFDI}) ${i.description}`,
                  value: i.c_UsoCFDI,
                }))}
                loading={cfdis.isLoading}
                disabled={cfdis.isLoading}
                placeholder={
                  cfdis.isLoading
                    ? 'Cargando Uso de Cfdi...'
                    : 'Selecciona una opción'
                }
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="Forma de pago"
              name="payment_method"
              rules={[
                { required: true, message: 'Selecciona la forma de pago' },
              ]}
            >
              <Select
                options={paymentMethods.data.map((i) => ({
                  label: `(${i.c_MetodoPago}) ${i.description}`,
                  value: i.c_MetodoPago,
                }))}
                loading={paymentMethods.isLoading}
                disabled={paymentMethods.isLoading}
                placeholder={
                  paymentMethods.isLoading
                    ? 'Cargando formas de pago...'
                    : 'Selecciona una opción'
                }
              />
            </Form.Item>
          </Col>
        </Row>
        {errorInvoiceConcepts && (
          <Alert
            message={`${errorInvoiceConcepts} ${totalInvoicePercentage}%`}
            type="error"
            showIcon
            className="mb-4"
          />
        )}
        <Table
          columns={columns}
          dataSource={invoiceConcepts}
          rowKey="id"
          pagination={{ hideOnSinglePage: true }}
        />
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state: {
  catalogs: CatalogsState;
  levita: LevitaState;
}): { catalogsState: CatalogsState; modalState: ModalLevita } => {
  return {
    catalogsState: state.catalogs,
    modalState: state.levita.modal,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    modal: bindActionCreators(
      {
        open: openModal,
        close: closeModal,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddBilling);
