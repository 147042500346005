import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import axios from 'axios';
import { env, getPath } from 'configs/EnvironmentConfig';
import { PayingCompany } from 'interfaces';

export interface PayingCompaniesState {
  isLoading: boolean;
  data: PayingCompany[];
}

const initialState: PayingCompaniesState = {
  isLoading: false,
  data: [],
};

const RESOURCE = getPath('payingCompanies', 'payingCompanies');

export const fetchPayingCompanies = createAsyncThunk(
  'payingCompanies',
  (_, { getState }) => {
    const { payingCompanies } = getState() as {
      payingCompanies: PayingCompaniesState;
    };

    if (payingCompanies.data.length) {
      return payingCompanies.data;
    }

    return axios
      .get<PayingCompany[]>(`${env?.API_ENDPOINT_URL}/${RESOURCE}`)
      .then((resp) => resp.data);
  }
);

export const payingCompaniesSlice = createSlice({
  name: 'payingCompanies',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPayingCompanies.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPayingCompanies.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchPayingCompanies.rejected, (state, action) => {
        state.isLoading = false;
        notification.error({
          message: 'Error al obtener PayingCompanies',
          description: action?.error?.message || 'Error desconocido',
        });
      });
  },
});

export default payingCompaniesSlice.reducer;
