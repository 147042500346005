import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import {
  Button,
  Card,
  Col,
  Popconfirm,
  Row,
  Spin,
  Table,
  Tooltip,
  Upload,
  message,
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import {
  DownloadOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { getToken } from 'utils/axios-request';
import './myCss.css';

const { Dragger } = Upload;

const EmployeeDocumentsContent = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { id } = useParams();

  const [documents, setDocuments] = useState([]);

  const commonProps = {
    name: 'file',
    multiple: false,
    action: 'https://mini.levita.app/api/documents',
    showUploadList: false,
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`Se cargo el archivo ${info.file.name} correctamente.`);
        getData();
      } else if (status === 'error') {
        message.error(`El archivo ${info.file.name} tuvo un problema.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };
  const actaNacimientoProps = {
    ...commonProps,
    data: {
      employee: id,
      type: 'actaNacimiento',
    },
  };
  const identificacionOficialProps = {
    ...commonProps,
    data: {
      employee: id,
      type: 'identificacionOficial',
    },
  };
  const csfProps = {
    ...commonProps,
    data: {
      employee: id,
      type: 'constanciaSituacionFiscal',
    },
  };
  const registroPoblacionProps = {
    ...commonProps,
    data: {
      employee: id,
      type: 'registroPoblacion',
    },
  };
  const nssProps = {
    ...commonProps,
    data: {
      employee: id,
      type: 'NSS',
    },
  };
  const comprobanteDomicilioProps = {
    ...commonProps,
    data: {
      employee: id,
      type: 'comprobanteDomicilio',
    },
  };
  const formaMigratoriaProps = {
    ...commonProps,
    data: {
      employee: id,
      type: 'formaMigratoria',
    },
  };
  const retencionInfonavitProps = {
    ...commonProps,
    data: {
      employee: id,
      type: 'retencionInfonavit',
    },
  };
  const caratulaEstadoDeBancoProps = {
    ...commonProps,
    data: {
      employee: id,
      type: 'caratulaEstadoDeBanco',
    },
  };
  const props = {
    ...commonProps,
    data: {
      employee: id,
      type: 'general',
    },
  };

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://mini.levita.app/api/documents?employee=${id}`,
        { headers: { ...getToken() } }
      );
      setDocuments(response?.data || []);
    } catch (err) {
      setError(true);
      message.error('Hubo un problema, intentelo mas tarde');
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const remainingDocuments = useMemo(() => {
    return _.filter(documents, (doc) => doc.type !== 'factura');
  }, [documents]);

  const handleDownloadClick = async (doc) => {
    try {
      const resp = await axios.get(
        `https://mini.levita.app/api/documents/${id}/${doc.name}`,
        { responseType: 'blob' }
      );
      saveAs(resp.data, doc.name);
    } catch (err) {
      console.log(err);
      message.error('Hubo un error al descargar el archivo');
    }
  };

  const handleDeleteClick = async (id) => {
    try {
      await axios.post('https://mini.levita.app/api/documents/delete', [id]);
      getData();
    } catch (err) {
      message.error('Ha ocurrido un error al eliminar el archivo');
    }
  };

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Descargar',
      dataIndex: 'download',
      key: 'download',
      render: (_, doc) => (
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          onClick={() => handleDownloadClick(doc)}
        />
      ),
      align: 'center',
    },
    {
      title: 'Eliminar',
      dataIndex: 'delete',
      key: 'delete',
      render: (_, { key }) => (
        <Popconfirm
          title="Eliminar el documento"
          onConfirm={() => handleDeleteClick(key)}
          onCancel={() => {}}
          okText="Si"
          cancelText="No"
        >
          <Button type="primary" icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
      align: 'center',
    },
  ];

  const dataSource = remainingDocuments.map((doc) => {
    return {
      key: doc._id,
      name: doc.name,
      type: doc.type,
    };
  });

  const {
    actaNacimientoExists,
    identificacionOficialExists,
    constanciaSituacionFiscalExists,
    registroPoblacionExists,
    constanciaSeguroSocialExists,
    comprobanteDomicilioExists,
    formaMigratoriaExists,
    avisoRetencionInfonavitExists,
    caratulaEstadoCuentaExists,
  } = useMemo(() => {
    const remainingDocumentsMapped = _.keyBy(remainingDocuments, 'type');
    return {
      actaNacimientoExists: remainingDocumentsMapped['actaNacimiento']
        ? true
        : false,
      identificacionOficialExists: remainingDocumentsMapped[
        'identificacionOficial'
      ]
        ? true
        : false,
      constanciaSituacionFiscalExists: remainingDocumentsMapped[
        'constanciaSituacionFiscal'
      ]
        ? true
        : false,
      registroPoblacionExists: remainingDocumentsMapped['registroPoblacion']
        ? true
        : false,
      constanciaSeguroSocialExists: remainingDocumentsMapped['NSS']
        ? true
        : false,
      comprobanteDomicilioExists: remainingDocumentsMapped[
        'comprobanteDomicilio'
      ]
        ? true
        : false,
      formaMigratoriaExists: remainingDocumentsMapped['formaMigratoria']
        ? true
        : false,
      avisoRetencionInfonavitExists: remainingDocumentsMapped[
        'retencionInfonavit'
      ]
        ? true
        : false,
      caratulaEstadoCuentaExists: remainingDocumentsMapped[
        'caratulaEstadoDeBanco'
      ]
        ? true
        : false,
    };
  }, [remainingDocuments]);

  return (
    <>
      <Spin spinning={loading}>
        <div>
          <Card>
            {!actaNacimientoExists && (
              <Dragger
                {...actaNacimientoProps}
                className=".ant-upload.ant-upload-drag"
                disabled={loading || error}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Acta de Nacimiento</p>
              </Dragger>
            )}
            {!identificacionOficialExists && (
              <Dragger
                {...identificacionOficialProps}
                style={{ height: 10 }}
                disabled={loading || error}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Identificación oficial vigente (formato digitalizado por ambos
                  lados del documento).
                </p>
              </Dragger>
            )}
            {!constanciaSituacionFiscalExists && (
              <Dragger
                {...csfProps}
                className=".ant-upload.ant-upload-drag"
                disabled={loading || error}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Constancia de Situación Fiscal actualizada (con antigüedad no
                  mayor a 3 meses).
                </p>
              </Dragger>
            )}
            {!registroPoblacionExists && (
              <Dragger
                {...registroPoblacionProps}
                className=".ant-upload.ant-upload-drag"
                disabled={loading || error}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Constancia de la clave única del Registro de Población (CURP).
                </p>
              </Dragger>
            )}
            {!constanciaSeguroSocialExists && (
              <Dragger
                {...nssProps}
                className=".ant-upload.ant-upload-drag"
                disabled={loading || error}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Constancia del Número de Seguro Social, Tarjeta del NSS o
                  copia de tarjeta.
                </p>
              </Dragger>
            )}
            {!comprobanteDomicilioExists && (
              <Dragger
                {...comprobanteDomicilioProps}
                className=".ant-upload.ant-upload-drag"
                disabled={loading || error}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Comprobante de domicilio (con antigüedad no mayor a 3 meses).
                </p>
              </Dragger>
            )}
            {!formaMigratoriaExists && (
              <Dragger
                {...formaMigratoriaProps}
                className=".ant-upload.ant-upload-drag"
                disabled={loading || error}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Forma Migratoria (FM) en caso de aplicar.
                </p>
              </Dragger>
            )}
            {!avisoRetencionInfonavitExists && (
              <Dragger
                {...retencionInfonavitProps}
                className=".ant-upload.ant-upload-drag"
                disabled={loading || error}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Aviso de retención de INFONAVIT si aplica.
                </p>
              </Dragger>
            )}
            {!caratulaEstadoCuentaExists && (
              <Dragger
                {...caratulaEstadoDeBancoProps}
                className=".ant-upload.ant-upload-drag"
                disabled={loading || error}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Carátula del estado de cuenta bancario donde se aprecie la
                  clabe bancaria, el número de cuenta y el nombre del
                  colaborador, así como la institución financiera.
                </p>
              </Dragger>
            )}
          </Card>
          <Card>
            <Row gutter={24}>
              <Col span={18}>
                <Table columns={columns} dataSource={dataSource} />
              </Col>
              <Col span={6}>
                <Dragger {...props} style={{ height: 10 }}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Haz click para subir un archivo
                  </p>
                </Dragger>
              </Col>
            </Row>
          </Card>
        </div>
      </Spin>
    </>
  );
};

export default EmployeeDocumentsContent;
