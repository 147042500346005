import React from 'react';
import {
  Card,
  Checkbox,
  Col,
  Form,
  InputNumber,
  Row,
  Select,
  Typography,
} from 'antd';
import { ConditionalRender } from '..';

const { Title } = Typography;

const VariableConcepts = () => {
  const form = Form.useFormInstance();

  const fondoAhorroEnabled = Form.useWatch<boolean>(
    ['payroll_variable_concepts', 'fondo_ahorro_enabled'],
    form
  );

  const variableConceptItems = [
    {
      name: ['payroll_variable_concepts', 'premio_asistencia_enabled'],
      label: 'Premio por asistencia',
    },
    {
      name: ['payroll_variable_concepts', 'premio_puntualidad_enabled'],
      label: 'Premio por puntualidad',
    },
    {
      name: ['payroll_variable_concepts', 'comisiones_enabled'],
      label: 'Comisiones',
    },
    {
      name: ['payroll_variable_concepts', 'gratificacion_enabled'],
      label: 'Gratificación',
    },
    {
      name: ['payroll_variable_concepts', 'sueldos_retroactivos_enabled'],
      label: 'Sueldos retroactivos',
    },
    {
      name: ['payroll_variable_concepts', 'client_discounts_enabled'],
      label: 'Descuentos',
    },
    {
      name: ['payroll_variable_concepts', 'devoluciones_enabled'],
      label: 'Devoluciones',
    },
    {
      name: ['payroll_variable_concepts', 'otras_deducciones_enabled'],
      label: 'Otras deducciones',
    },
    {
      name: ['payroll_variable_concepts', 'comedor_enabled'],
      label: 'Comedor',
    },
    {
      name: ['payroll_variable_concepts', 'bono_varios_enabled'],
      label: 'Bono varios',
    },
    {
      name: ['payroll_variable_concepts', 'ahorro_uniformes_enabled'],
      label: 'Ahorro para uniformes',
    },
    {
      name: ['payroll_variable_concepts', 'bono_productividad_enabled'],
      label: 'Bono de productividad',
    },
    {
      name: ['payroll_variable_concepts', 'fondo_ahorro_enabled'],
      label: 'Servicio de ahorro',
    },
  ];

  return (
    <Card>
      <Title level={3}>Conceptos Variables</Title>

      {variableConceptItems.map((item) => (
        <div className="pb-2" key={item.label}>
          <Form.Item name={item.name} valuePropName="checked" noStyle>
            <Checkbox>{item.label}</Checkbox>
          </Form.Item>
        </div>
      ))}

      <ConditionalRender condition={fondoAhorroEnabled}>
        <Title level={4} className="pt-4 pb-2">
          Configuración de Servicio de Ahorro
        </Title>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Form.Item
              name={[
                'payroll_variable_concepts',
                'fondo_ahorro_config',
                'tipo_descuento',
              ]}
              label="Tipo de Descuento"
            >
              <Select
                options={[
                  { label: 'Selecciona una opción', value: '' },
                  { label: 'Porcentaje', value: 'Porcentaje' },
                  { label: 'Monto Fijo', value: 'Monto Fijo' },
                ]}
                placeholder="Selecciona una opción"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={[
                'payroll_variable_concepts',
                'fondo_ahorro_config',
                'monto',
              ]}
              label="Monto / Porcentaje de descuento"
            >
              <InputNumber className="w-100" controls={false} min={0} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={[
                'payroll_variable_concepts',
                'fondo_ahorro_config',
                'quien_paga',
              ]}
              label="¿Quién paga?"
            >
              <Select
                options={[
                  { label: 'Selecciona una opción', value: '' },
                  { label: 'Paga todo el Empleado', value: 'Empleado' },
                  { label: 'Paga Cliente y Empleado', value: 'Cliente' },
                ]}
                placeholder="Selecciona una opción"
              />
            </Form.Item>
          </Col>
        </Row>
      </ConditionalRender>
    </Card>
  );
};

export default VariableConcepts;
