import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import {
  Button,
  Card,
  Col,
  Popconfirm,
  Row,
  Spin,
  Table,
  Upload,
  message,
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import {
  DownloadOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import './myCss.css';
import { getToken } from 'utils/axios-request';

const { Dragger } = Upload;

const DocumentsContent = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { id } = useParams();
  const [documents, setDocuments] = useState([]);

  const commonProps = {
    name: 'file',
    multiple: false,
    action: '/api/documents/upload',
    showUploadList: false,
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`Se cargo el archivo ${info.file.name} correctamente.`);
        getData();
      } else if (status === 'error') {
        message.error(`El archivo ${info.file.name} tuvo un problema.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const actaConstitutivaProps = {
    ...commonProps,
    data: {
      client: id,
      type: 'actaConstitutiva',
    },
  };
  const poderNotarialProps = {
    ...commonProps,
    data: {
      client: id,
      type: 'poderNotarial',
    },
  };
  const folioMercantilProps = {
    ...commonProps,
    data: {
      client: id,
      type: 'folioMercantil',
    },
  };
  const representanteLegalProps = {
    ...commonProps,
    data: {
      client: id,
      type: 'representanteLegal',
    },
  };
  const comprobanteDomicilioProps = {
    ...commonProps,
    data: {
      client: id,
      type: 'comprobanteDomicilio',
    },
  };
  const props = {
    ...commonProps,
    data: {
      client: id,
      type: 'general',
    },
  };

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/api/documents?client=${id}`, {
        headers: { ...getToken() },
      });
      setDocuments(response?.data || []);
    } catch (err) {
      setError(true);
      console.error(err);
      message.error('Hubo un problema, intentelo mas tarde');
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const remainingDocuments = useMemo(() => {
    return _.filter(documents, (doc) => doc.type !== 'factura');
  }, [documents]);

  const handleDownloadClick = async (doc) => {
    try {
      const resp = await axios.get(doc.downloadUrl, { responseType: 'blob' });
      saveAs(resp.data, doc.name);
    } catch (err) {
      console.error(err);
      message.error('Hubo un error al descargar el archivo');
    }
  };

  const handleDeleteClick = async (id) => {
    try {
      await axios.post(`/api/documents/delete/${id}`);
      getData();
    } catch (err) {
      console.error(err);
      message.error('Ha ocurrido un error al eliminar el archivo');
    }
  };

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Descargar',
      dataIndex: 'download',
      key: 'download',
      render: (_, doc) => (
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          onClick={() => handleDownloadClick(doc)}
        />
      ),
      align: 'center',
    },
    {
      title: 'Eliminar',
      dataIndex: 'delete',
      key: 'delete',
      render: (_, { key }) => (
        <Popconfirm
          title="Eliminar el documento"
          onConfirm={() => handleDeleteClick(key)}
          onCancel={() => {}}
          okText="Si"
          cancelText="No"
        >
          <Button type="primary" icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
      align: 'center',
    },
  ];

  const dataSource = remainingDocuments.map((doc) => {
    return {
      key: doc._id,
      ...doc,
    };
  });

  const {
    actaConstitutivaExists,
    poderNotarialExists,
    folioMercantilExists,
    identificacionOficialExists,
    comprobanteDomicilioExists,
  } = useMemo(() => {
    const remainingDocumentsMapped = _.keyBy(remainingDocuments, 'type');
    return {
      actaConstitutivaExists: remainingDocumentsMapped['actaConstitutiva']
        ? true
        : false,
      poderNotarialExists: remainingDocumentsMapped['poderNotarial']
        ? true
        : false,
      folioMercantilExists: remainingDocumentsMapped['folioMercantil']
        ? true
        : false,
      identificacionOficialExists: remainingDocumentsMapped[
        'representanteLegal'
      ]
        ? true
        : false,
      comprobanteDomicilioExists: remainingDocumentsMapped[
        'comprobanteDomicilio'
      ]
        ? true
        : false,
    };
  }, [remainingDocuments]);

  return (
    <>
      <Spin spinning={loading}>
        <div>
          <Card>
            {!actaConstitutivaExists && (
              <Dragger
                {...actaConstitutivaProps}
                className=".ant-upload.ant-upload-drag"
                disabled={loading || error}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Acta constitutiva y sus modificadores
                </p>
                {actaConstitutivaExists && (
                  <CheckCircleOutlined
                    style={{ fontSize: 30, color: 'green' }}
                  />
                )}
              </Dragger>
            )}
            {!poderNotarialExists && (
              <Dragger
                {...poderNotarialProps}
                style={{ height: 10 }}
                disabled={loading || error}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Poder notarial</p>
                {poderNotarialExists && (
                  <CheckCircleOutlined
                    style={{ fontSize: 30, color: 'green' }}
                  />
                )}
              </Dragger>
            )}
            {!folioMercantilExists && (
              <Dragger
                {...folioMercantilProps}
                className=".ant-upload.ant-upload-drag"
                disabled={loading || error}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Folio mercantil</p>
                {folioMercantilExists && (
                  <CheckCircleOutlined
                    style={{ fontSize: 30, color: 'green' }}
                  />
                )}
              </Dragger>
            )}
            {!identificacionOficialExists && (
              <Dragger
                {...representanteLegalProps}
                className=".ant-upload.ant-upload-drag"
                disabled={loading || error}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Identificacion oficial vigente del representante legal
                </p>
                {identificacionOficialExists && (
                  <CheckCircleOutlined
                    style={{ fontSize: 30, color: 'green' }}
                  />
                )}
              </Dragger>
            )}
            {!comprobanteDomicilioExists && (
              <Dragger
                {...comprobanteDomicilioProps}
                className=".ant-upload.ant-upload-drag"
                disabled={loading || error}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Comprobante de domicilio</p>
                {comprobanteDomicilioExists && (
                  <CheckCircleOutlined
                    style={{ fontSize: 30, color: 'green' }}
                  />
                )}
              </Dragger>
            )}
          </Card>
          <Card>
            <Row gutter={24}>
              <Col span={18}>
                <Table columns={columns} dataSource={dataSource} />
              </Col>
              <Col span={6}>
                <Dragger {...props} style={{ height: 10 }}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Haz click para subir un archivo
                  </p>
                </Dragger>
              </Col>
            </Row>
          </Card>
        </div>
      </Spin>
    </>
  );
};

export default DocumentsContent;
