import { Card, Spin, Table, Typography } from 'antd';
import React from 'react';
import useBranchOffices from './useBranchOffices';

const { Title } = Typography;

const BranchOffices = () => {
  const { state } = useBranchOffices();
  const { isLoading, tableColumns, tablePagination, branchOffices } = state;

  return (
    <Card className="pb-4">
      <Spin spinning={isLoading}>
        <Title level={3}>Lista de Sucursales/Oficinas</Title>
        <Table
          columns={tableColumns}
          dataSource={branchOffices}
          pagination={tablePagination}
          rowKey="_id"
        />
      </Spin>
    </Card>
  );
};

export default BranchOffices;
