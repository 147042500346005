import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  SaveOutlined,
  LockOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Tabs,
  Tooltip,
  message,
  notification,
} from 'antd';

import Flex from 'components/shared-components/Flex';
import { env, getPath } from 'configs/EnvironmentConfig';
import { GroupEntities, fetchGroupEntities } from 'store/slices/clientsSlice';
import {
  PayingCompaniesState,
  fetchPayingCompanies,
} from 'store/slices/payingCompaniesSlice';
import {
  Municipalities,
  States,
  fetchMunicipalities,
  fetchStates,
} from 'store/slices/statesAndMunicipalitySlice';
import AddContact, { ADD_CONTACT_MODAL } from '../modals/AddContact';
import { ModalActions, closeModal, openModal } from 'store/slices/levitaSlice';
import AddBankAccount, {
  ADD_BANK_ACCOUNT_MODAL,
} from '../modals/AddBankAccount';
import { getToken } from 'utils/axios-request';

const RESOURCES = {
  SEARCH_CLIENTS: getPath('client', 'clientHandler'),
  BANK_ACCOUNTS: getPath('bankAccounts', 'bankAccounts'),
};

export const getClient = (id: string) =>
  axios
    .get(`${env?.API_ENDPOINT_URL}/${RESOURCES.SEARCH_CLIENTS}`, {
      params: { id },
      headers: { ...getToken() },
    })
    .then((resp) => resp.data?.[0]);

const getBankAccounts = (client: string) =>
  axios
    .post(`${env?.API_ENDPOINT_URL}/${RESOURCES.BANK_ACCOUNTS}`, {
      client,
    })
    .then((resp) => resp.data);

const inititalFormValues = {
  basic: {
    active: false,
    numberOfEmployees: '',
  },
  general: {
    is_migration: false,
    bankCards: false,
    IMSSChargeDate: '',
    bank: '',
    trade_name: '',
    businessName: '',
    cellPhone: '',
    chargeDate: '',
    dischargeDate: '',
    industry: '',
    rfc: '',
    telephone: '',
    wageBank: '',
    webPage: '',
    state: '',
    municipality: '',
    work_address: '',
    zipCode: '',
  },
  payingCompanies: [],
  group_entity: '',
  laborae_db_name: '',
};

interface Props {
  states: States;
  municipalities: Municipalities;
  groupEntitiesState: GroupEntities;
  payingCompaniesState: PayingCompaniesState;
  modal: ModalActions;
  dispatchFetchAllData: () => void;
  dispatchFetchMunicipalities: (stateId: string) => void;
}

interface OperativeContact {
  email: string;
  id: string;
  name: string;
}

const ClientInformation = ({
  modal,
  states,
  municipalities,
  groupEntitiesState,
  payingCompaniesState,
  dispatchFetchAllData,
  dispatchFetchMunicipalities,
}: Props) => {
  const { id = '' } = useParams();

  const [infoForm] = Form.useForm();

  const [client, setClient] = React.useState<{ [key: string]: any }>({});
  const [isEditingForm, setIsEditingForm] = React.useState(true);
  const [isLoadingClient, setIsLoadingClient] = React.useState(true);

  const handlePasswordClick = async (oc: OperativeContact) => {
    try {
      const resp = await axios.post(
        'https://mini.levita.app/api/generateOCPassword',
        {
          clientId: id,
          operativeContact: oc,
        }
      );
      message.success(resp?.data?.data);
    } catch (err) {
      message.error('No se ha podido generar la contraseña correctamente');
    }
  };

  const contactColumns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Domicilio',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Teléfonos',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (_: any, oc: OperativeContact) => (
        <Space>
          <Tooltip title="Genera contraseña para contacto operativo">
            <Button
              size="small"
              type="text"
              icon={<LockOutlined />}
              onClick={() => handlePasswordClick(oc)}
            />
          </Tooltip>
          <Button size="small" type="text" icon={<EditOutlined />} />
          <Button
            onClick={() => {
              Modal.confirm({
                title: 'Contacto',
                content: '¿Esta seguro que desea eliminar el contacto?',
              });
            }}
            size="small"
            type="text"
            icon={<DeleteOutlined />}
          />
        </Space>
      ),
    },
  ];

  const accountColumns = [
    {
      title: 'Banco',
      dataIndex: 'bank',
      key: 'bank',
    },
    {
      title: 'Cuenta clabe',
      dataIndex: 'CLABE',
      key: 'CLABE',
    },
    {
      title: 'Número de cuenta',
      dataIndex: 'account_number',
      key: 'account_number',
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: () => (
        <Space>
          <Button size="small" type="text" icon={<EditOutlined />} />
          <Button
            onClick={() => {
              Modal.confirm({
                title: 'Cuenta de banco',
                content: '¿Esta seguro que desea eliminar la cuenta de banco?',
              });
            }}
            size="small"
            type="text"
            icon={<DeleteOutlined />}
          />
        </Space>
      ),
    },
  ];

  const onChangeAddressState = (stateId: string) => {
    dispatchFetchMunicipalities(stateId);
  };

  const tabItems = [
    {
      label: 'Información básica',
      key: 'item-1',
      forceRender: true,
      children: (
        <>
          <Form.Item
            label="Activo/Inactivo"
            name={['basic', 'active']}
            valuePropName="checked"
            className="swtich-item"
            labelCol={{ className: 'mr-2' }}
            wrapperCol={{ className: 'w-auto' }}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label="Es Migración"
            name={['general', 'is_migration']}
            valuePropName="checked"
            className="swtich-item"
            labelCol={{ className: 'mr-2' }}
            wrapperCol={{ className: 'w-auto' }}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label="Número de empleados"
            name={['basic', 'numberOfEmployees']}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Sociedades" name="payingCompanies">
            <Select
              showSearch
              mode="multiple"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={payingCompaniesState.data.map((item) => ({
                label: item?.businessName,
                value: item?._id,
              }))}
              loading={payingCompaniesState.isLoading}
              placeholder={
                payingCompaniesState.isLoading
                  ? 'Cargando sociedades...'
                  : 'Selecciona las sociedades'
              }
            />
          </Form.Item>
          {/* Pendiente */}
          <Form.Item label="Grupo de cliente" name="group_entity">
            <Select
              showSearch
              placeholder={
                groupEntitiesState?.isLoading
                  ? 'Cargando información'
                  : 'Selecciona el grupo de cliente'
              }
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={(groupEntitiesState?.data || []).map((item: any) => ({
                label: item?.name,
                value: item?._id,
              }))}
              loading={groupEntitiesState?.isLoading}
            />
          </Form.Item>
        </>
      ),
    },
    {
      label: 'Información general',
      key: 'item-2',
      forceRender: true,
      children: (
        <>
          <Form.Item
            label="Tarjetas bancarias"
            name={['general', 'bankCards']}
            valuePropName="checked"
            className="swtich-item"
            labelCol={{ className: 'mr-2' }}
            wrapperCol={{ className: 'w-auto' }}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label="Fecha de alta en IMSS"
            name={['general', 'IMSSChargeDate']}
          >
            <DatePicker
              placeholder="Seleccionar fecha"
              format="DD-MM-YYYY"
              style={{ minWidth: '180px' }}
            />
          </Form.Item>
          {/* Pendiente */}
          <Form.Item label="Bancos" name={['general', 'bank']}>
            <Input />
          </Form.Item>
          <Form.Item label="Nombre comercial" name={['general', 'trade_name']}>
            <Input />
          </Form.Item>
          <Form.Item label="Razón social" name={['general', 'businessName']}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Celular"
            name={['general', 'cellPhone']}
            rules={[
              {
                validator: (_, value) => {
                  const phoneRegex = /^\d{10}$/;

                  if (value && !phoneRegex.test(value)) {
                    return Promise.reject(
                      'El número debe tener 10 dígitos numéricos.'
                    );
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Flex alignItems="center" justifyContent="around">
            <Form.Item
              label="Fecha de alta en el sistema Intela"
              name={['general', 'chargeDate']}
            >
              <DatePicker
                placeholder="Seleccionar fecha"
                style={{ minWidth: '200px' }}
              />
            </Form.Item>
            <Form.Item
              label="Fecha de baja de la empresa anterior"
              name={['general', 'dischargeDate']}
            >
              <DatePicker
                placeholder="Seleccionar fecha"
                style={{ minWidth: '200px' }}
              />
            </Form.Item>
          </Flex>
          <Form.Item label="Giro" name={['general', 'industry']}>
            <Input />
          </Form.Item>
          <Form.Item label="RFC" name={['general', 'rfc']}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Teléfono de oficina"
            name={['general', 'telephone']}
            rules={[
              {
                validator: (_, value) => {
                  const phoneRegex = /^\d{10}$/;

                  if (value && !phoneRegex.test(value)) {
                    return Promise.reject(
                      'El número debe tener 10 dígitos numéricos.'
                    );
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input />
          </Form.Item>
          {/* Este campo ya no se usa en su lugar se usan las cuentas de banco
                <Form.Item
                  label="Banco para nómina"
                  name={['general', 'wageBank']}
                >
                  <Input />
                </Form.Item> */}
          <Form.Item
            label="Página web"
            name={['general', 'webPage']}
            rules={[
              {
                type: 'url',
                message: 'Por favor, ingresa un enlace de página válido.',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Estado" name={['general', 'state']}>
            <Select
              showSearch
              placeholder="Selecciona el estado"
              optionFilterProp="children"
              onChange={onChangeAddressState}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={states.data.map((item) => ({
                label: item?.name,
                value: item?._id,
              }))}
              loading={states.isLoading}
            />
          </Form.Item>
          <Form.Item label="Municipio" name={['general', 'municipality']}>
            <Select
              showSearch
              placeholder="Selecciona las sociedades"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={municipalities.data.map((item) => ({
                label: item.name,
                value: item._id,
              }))}
              loading={municipalities.isLoading}
              disabled={!municipalities.data.length}
            />
          </Form.Item>
          <Form.Item label="Dirección" name={['general', 'work_address']}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Código postal"
            name={['general', 'zipCode']}
            rules={[
              {
                validator: (_, value) => {
                  const postalCodeRegex = /^\d{5}$/;

                  if (value && !postalCodeRegex.test(value)) {
                    return Promise.reject(
                      'El código postal debe tener 5 dígitos numéricos.'
                    );
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Base de datos Laborae" name="laborae_db_name">
            <Input />
          </Form.Item>
        </>
      ),
    },
    {
      label: 'Contacto',
      key: 'item-3',
      forceRender: true,
      children: (
        <>
          <Form.Item
            label="Habilitar exportar prenomina en excel"
            name={['general', 'exportExcel']}
            valuePropName="checked"
            className="swtich-item"
            labelCol={{ className: 'mr-2' }}
            wrapperCol={{ className: 'w-auto' }}
          >
            <Switch />
          </Form.Item>
          <Flex justifyContent="end">
            <Button
              onClick={() => modal.open(ADD_CONTACT_MODAL)}
              type="primary"
              icon={<PlusOutlined />}
              shape="circle"
              title="Add new contact"
            />
          </Flex>
          <Table
            dataSource={client?.contacts || []}
            columns={contactColumns}
            rowKey="id"
            pagination={{
              hideOnSinglePage: true,
            }}
          />
        </>
      ),
    },
    {
      label: 'Cuentas bancarias / Bancos',
      key: 'item-4',
      children: (
        <>
          <Flex justifyContent="end">
            <Button
              onClick={() => modal.open(ADD_BANK_ACCOUNT_MODAL)}
              type="primary"
              icon={<PlusOutlined />}
              shape="circle"
              title="Add new bank account"
            />
          </Flex>
          <Table
            dataSource={client?.bankAccounts || []}
            columns={accountColumns}
            rowKey="_id"
            pagination={{
              hideOnSinglePage: true,
            }}
          />
        </>
      ),
    },
  ];

  const fetchClient = React.useCallback(
    async (id: string) => {
      setIsLoadingClient(true);
      try {
        const bankAccounts = await getBankAccounts(id);
        const data = await getClient(id);
        const clientResp = data;

        const { IMSSChargeDate, chargeDate, dischargeDate } =
          clientResp.general;

        const parseDate = (date: string | undefined) =>
          date ? moment(date) : null;

        clientResp.general.IMSSChargeDate = parseDate(IMSSChargeDate);
        clientResp.general.chargeDate = parseDate(chargeDate);
        clientResp.general.dischargeDate = parseDate(dischargeDate);
        clientResp.bankAccounts = bankAccounts;

        setClient((prev) => ({ ...prev, ...clientResp }));
        infoForm.setFieldsValue(clientResp);
      } catch (error: any) {
        notification.error({
          message: 'Error al obtener Client',
          description: error?.message || 'Error desconocido',
        });
      } finally {
        setIsLoadingClient(false);
      }
    },
    [infoForm]
  );

  const onClickSubmitButton = async () => {
    setIsEditingForm(!isEditingForm);

    if (!isEditingForm) {
      return;
    }

    const formValues = await infoForm.validateFields();

    console.log({ formValidation: formValues });
  };

  const onCreateContact = () => {
    console.log('Contact created');
  };

  const onCreateBankAccount = () => {
    console.log('BankAccount created');
  };

  React.useEffect(() => {
    fetchClient(id);
  }, [fetchClient, id]);

  React.useEffect(() => {
    dispatchFetchAllData();
  }, [dispatchFetchAllData]);

  return (
    <>
      <Card>
        <div className="text-right mb-3">
          <Button
            onClick={onClickSubmitButton}
            type="primary"
            icon={isEditingForm ? <SaveOutlined /> : <EditOutlined />}
            shape="circle"
            size="small"
          />
        </div>
        <Form
          layout="vertical"
          form={infoForm}
          initialValues={inititalFormValues}
          disabled={!isEditingForm}
        >
          <Spin spinning={isLoadingClient}>
            <Tabs defaultActiveKey="1" items={tabItems} />
          </Spin>
        </Form>
      </Card>

      <AddContact callback={onCreateContact} />
      <AddBankAccount callback={onCreateBankAccount} />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    groupEntitiesState: state.clients.groupEntities,
    payingCompaniesState: state.payingCompanies,
    states: state.statesAndMunicipality.states,
    municipalities: state.statesAndMunicipality.municipalities,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    ...bindActionCreators(
      {
        dispatchFetchMunicipalities: fetchMunicipalities,
        dispatchFetchAllData: () => {
          return () => {
            dispatch(fetchStates());
            dispatch(fetchGroupEntities());
            dispatch(fetchPayingCompanies());
          };
        },
      },
      dispatch
    ),
    modal: bindActionCreators(
      {
        open: openModal,
        close: closeModal,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientInformation);
