import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
import { Button, Card, Col, Popconfirm, Row, Spin, Table, message } from 'antd';
import { DownloadOutlined, DeleteOutlined } from '@ant-design/icons';
import { getToken } from 'utils/axios-request';

const BillingContent = () => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const [documents, setDocuments] = useState([]);

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://mini.levita.app/api/documents?client=${id}`,
        // `http://127.0.0.1:5001/levita-app/us-central1/documents?client=${id}`,
        { headers: { ...getToken() } }
      );
      setDocuments(response?.data || []);
    } catch (err) {
      message.error('Hubo un problema, intentelo mas tarde');
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const billingDocuments = useMemo(() => {
    return _.filter(documents, (doc) => doc.type === 'factura');
  }, [documents]);

  const handleDownloadClick = (url) => {
    console.log(url, 'url');
  };

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Descargar',
      dataIndex: 'download',
      key: 'download',
      render: (_, { download }) => (
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          onClick={() => handleDownloadClick(download)}
        />
      ),
      align: 'center',
    },
    {
      title: 'Eliminar',
      dataIndex: 'delete',
      key: 'delete',
      render: () => (
        <Popconfirm
          title="Eliminar el documento"
          onConfirm={() => {}}
          onCancel={() => {}}
          okText="Si"
          cancelText="No"
        >
          <Button type="primary" icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
      align: 'center',
    },
  ];

  const dataSource = billingDocuments.map((doc) => {
    return {
      key: doc._id,
      name: doc.name,
      download: doc.public_url,
    };
  });

  return (
    <>
      <Spin spinning={loading}>
        <div>
          <Card>
            <Row gutter={24}>
              <Col span={24}>
                <Table columns={columns} dataSource={dataSource} />
              </Col>
            </Row>
          </Card>
        </div>
      </Spin>
    </>
  );
};

export default BillingContent;
