import { Card, Form, Input, InputNumber, Select, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';

import { PayingCompany, Payroll } from 'interfaces';
import { PayingCompaniesState } from 'store/slices/payingCompaniesSlice';

const { Title } = Typography;

interface Props {
  selectedPayroll: undefined | Payroll;
  clientPayingCompanies: PayingCompany[];
  billingCompanyOptions: PayingCompany[];
  payingCompaniesState: PayingCompaniesState;
}

const Companies = ({
  selectedPayroll,
  payingCompaniesState,
  clientPayingCompanies,
  billingCompanyOptions,
}: Props) => {
  return (
    <Card>
      <Title level={3}>Empresas</Title>
      <Form.Item label="Es de alta dirección">
        <Input
          value={selectedPayroll?.is_high_direction ? 'Sí' : 'No'}
          bordered={false}
          readOnly
        />
      </Form.Item>
      <Form.Item label="Empresa que dispersa" name="dispersing_company">
        <Select
          options={clientPayingCompanies.map((i) => ({
            label: i.name,
            value: i._id,
          }))}
        />
      </Form.Item>
      <Form.Item label="Empresa que factura" name="billing_company">
        <Select
          options={billingCompanyOptions.map((i) => ({
            label: i.name,
            value: i._id,
          }))}
          disabled={selectedPayroll?.is_high_direction ? false : true}
        />
      </Form.Item>
      <Form.Item
        label="Empresa que dispersa NOM035"
        name="dispersing_company_nom035"
      >
        <Select
          options={payingCompaniesState.data.map((i) => ({
            label: i.name,
            value: i._id,
          }))}
          disabled
        />
      </Form.Item>
      <Form.Item label="Comisión" name="commission">
        <InputNumber
          controls={false}
          addonAfter="%"
          className="w-100"
          min={0}
          max={100}
        />
      </Form.Item>
    </Card>
  );
};

const mapStateToProps = (state: {
  payingCompanies: PayingCompaniesState;
}): {
  payingCompaniesState: PayingCompaniesState;
} => {
  return {
    payingCompaniesState: state.payingCompanies,
  };
};

export default connect(mapStateToProps)(Companies);
