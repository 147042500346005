import { DatePicker, Form, Input, InputNumber, Select, Switch } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { CatalogsState } from 'store/slices/catalogs';
import { datePickerStyles } from '.';

interface Props {
  catalogsState: CatalogsState;
}

const CommercialInformation = ({ catalogsState }: Props) => {
  const { holidays } = catalogsState;

  return (
    <div className="py-4">
      <Form.Item
        label="Porcentaje de comisión negociada"
        name={['kardex', 'payment', 'charging_commission_formula']}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Salario Mínimo por cliente"
        name={['kardex', 'payment', 'lowest_wage_permitted']}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Prima vacacional"
        name={['payroll_default_values', 'holidays_bonus_percentage']}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Aguinaldo"
        name={['payroll_default_values', 'christmas_bonus_days']}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Vacaciones"
        name={
          holidays.isLoading
            ? undefined
            : ['payroll_default_values', 'holidays']
        }
      >
        <Select
          options={holidays.data.map((i) => ({
            value: i._id,
            label: i.type,
          }))}
          placeholder={
            holidays.isLoading
              ? 'Cargando vacaciones...'
              : 'Selecciona una opción'
          }
          loading={holidays.isLoading}
          disabled={holidays.isLoading}
        />
      </Form.Item>
      <Form.Item
        label="Monto primera dispersión"
        name={['kardex', 'payment', 'firstDispersion']}
      >
        <InputNumber style={datePickerStyles} controls={false} />
      </Form.Item>
      <Form.Item
        label="Fecha Primera Dispersión"
        name={['kardex', 'payment', 'dateFirstDisperssion']}
      >
        <DatePicker
          placeholder="Selecciona una fecha"
          style={datePickerStyles}
          format={'DD MMM YYYY'}
        />
      </Form.Item>
      <Form.Item
        label="¿Tiene etapas?"
        valuePropName="checked"
        name={['kardex', 'payment', 'bankCards']}
      >
        <Switch />
      </Form.Item>
      <Form.Item
        label="Fecha de Compromiso Documentos"
        name={['kardex', 'payment', 'documents']}
      >
        <DatePicker
          placeholder="Selecciona una fecha"
          style={datePickerStyles}
          format={'DD MMM YYYY'}
        />
      </Form.Item>
    </div>
  );
};

const mapStateToProps = (state: {
  catalogs: CatalogsState;
}): {
  catalogsState: CatalogsState;
} => {
  return {
    catalogsState: state.catalogs,
  };
};

export default connect(mapStateToProps)(CommercialInformation);
