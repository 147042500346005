import React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  List,
  Spin,
  notification,
} from 'antd';
import { PlusOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import Flex from 'components/shared-components/Flex';
import { env, getPath } from 'configs/EnvironmentConfig';
import { getClient } from './ClientDetail';
import AddDepartment, { ADD_DEPARTMENT_MODAL } from '../modals/AddDepartment';
import { ModalActions, closeModal, openModal } from 'store/slices/levitaSlice';

import './myCss.css';

const { Search } = Input;

export interface Department {
  _id: string;
  deleted_at: null;
  created_at: Date;
  updated_at: Date;
  name: string;
  risk: number;
}

const RESOURCE = getPath('departments', 'departments');

const getDepartments = () =>
  axios
    .get<Department[]>(`${env?.API_ENDPOINT_URL}/${RESOURCE}`)
    .then((resp) => resp.data);

const filterDepartments = (departments: Department[], search: string) =>
  departments.filter((item) =>
    (item?.name ?? '').toLowerCase().includes(search.toLowerCase())
  );

interface Props {
  modal: ModalActions;
}

const Departments = ({ modal }: Props) => {
  const { id = '' } = useParams();

  const [search, setSearch] = React.useState('');
  const [selectedDepartments, setSelectedDepartments] = React.useState<
    String[]
  >([]);
  const [isEditingForm, setIsEditingForm] = React.useState(true);
  const [departments, setDepartments] = React.useState<Department[]>([]);
  const [isLoadingDepartments, setIsLoadingDepartments] = React.useState(true);

  const departmentsFiltered = filterDepartments(departments, search);

  const fetchDepartments = React.useCallback(async () => {
    setIsLoadingDepartments(true);
    try {
      const [departmentResp, clientResp] = await Promise.all([
        getDepartments(),
        getClient(id),
      ]);

      const { departments } = clientResp?.raw;

      setDepartments(departmentResp);
      setSelectedDepartments(departments);
    } catch (error: any) {
      notification.error({
        message: 'Error al obtener Departments',
        description: error?.message || 'Error desconocido',
      });
    } finally {
      setIsLoadingDepartments(false);
    }
  }, [id]);

  const onEditForm = () => setIsEditingForm(true);

  const onCreateDepartment = () => {
    fetchDepartments();
    console.log('onCreateDepartment');
  };

  const handleToggleCheckbox = (id: string) => {
    if (selectedDepartments.includes(id)) {
      setSelectedDepartments(
        selectedDepartments.filter((selectedId) => selectedId !== id)
      );
    } else {
      setSelectedDepartments([...selectedDepartments, id]);
    }
  };

  const onSubmit = () => {
    console.log({ selectedDepartments });

    setIsEditingForm(false);
  };

  React.useEffect(() => {
    fetchDepartments();
  }, [fetchDepartments]);

  return (
    <>
      <Card>
        <Spin spinning={isLoadingDepartments}>
          <Flex justifyContent="end">
            {isEditingForm ? (
              <Button
                type="primary"
                icon={<SaveOutlined />}
                shape="circle"
                size="small"
                onClick={onSubmit}
              />
            ) : (
              <Button
                type="primary"
                icon={<EditOutlined />}
                shape="circle"
                size="small"
                onClick={onEditForm}
              />
            )}
          </Flex>
          <Flex justifyContent="center" className="pb-2">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              shape="circle"
              size="small"
              className="mr-3"
              onClick={() => modal.open(ADD_DEPARTMENT_MODAL)}
            />
            <Search
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Buscar departamento"
              style={{ maxWidth: '400px' }}
              allowClear
            />
          </Flex>
          <div
            className="mt-4"
            style={{
              maxHeight: '450px',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            <Form disabled={!isEditingForm}>
              <DepartmentList
                departments={departmentsFiltered}
                onToggleCheckbox={handleToggleCheckbox}
                selectedDepartments={selectedDepartments}
              />
            </Form>
          </div>
        </Spin>
      </Card>
      <AddDepartment callback={onCreateDepartment} />
    </>
  );
};

interface ListProps {
  departments: Department[];
  selectedDepartments: String[];
  onToggleCheckbox: (id: string) => void;
}

const DepartmentList = ({
  departments,
  selectedDepartments,
  onToggleCheckbox,
}: ListProps) => {
  const renderItem = (department: Department) => {
    const isChecked = selectedDepartments.includes(department._id);

    const handleCheckboxChange = () => {
      onToggleCheckbox(department._id);
    };

    return (
      <List.Item key={department._id}>
        <Checkbox
          checked={isChecked}
          onChange={handleCheckboxChange}
          className="mr-2 text-uppercase"
        >
          {department.name}
        </Checkbox>
      </List.Item>
    );
  };

  return <List dataSource={departments} renderItem={renderItem} />;
};

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    modal: bindActionCreators(
      {
        open: openModal,
        close: closeModal,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Departments);
