import { DatePicker, Form } from 'antd';
import React from 'react';
import { datePickerStyles } from '.';

const Operation = () => {
  return (
    <div className="py-4">
      <Form.Item
        label="Fecha de registro"
        name={['kardex', 'operation', 'registration']}
      >
        <DatePicker
          placeholder="Selecciona una fecha"
          style={datePickerStyles}
          format={'DD MMM YYYY'}
        />
      </Form.Item>
      <Form.Item
        label="Fecha de alta IMSS"
        name={['kardex', 'operation', 'imss']}
      >
        <DatePicker
          placeholder="Selecciona una fecha"
          style={datePickerStyles}
          format={'DD MMM YYYY'}
        />
      </Form.Item>
      <Form.Item label="Fecha de salida" name={['kardex', 'operation', 'exit']}>
        <DatePicker
          placeholder="Selecciona una fecha"
          style={datePickerStyles}
          format={'DD MMM YYYY'}
        />
      </Form.Item>
    </div>
  );
};

export default Operation;
