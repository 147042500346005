import React, { useCallback } from 'react';
import _ from 'lodash';
import { StarFilled } from '@ant-design/icons';
import { Col, Empty, Form, Menu, Row, Skeleton, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import { ClientState } from 'store/interfaces';
import {
  fetchAdc,
  fetchCfdis,
  fetchWorks,
  fetchHolidays,
  fetchNoministas,
  fetchDispersadores,
  fetchComisionistas,
  fetchPaymentMethods,
  fetchPayrollFrequencies,
  fetchModifierTypes,
} from 'store/httpActions';
import {
  PayingCompaniesState,
  fetchPayingCompanies,
} from 'store/slices/payingCompaniesSlice';

import {
  Banks,
  General,
  Billing,
  Companies,
  Responsibles,
  VariableConcepts,
  CommissionAgents,
  WhoAbsorbsConcepts,
  PerceptionModifiers,
  OperationalContacts,
} from './components';
import usePayroll from './usePayroll';

import './style.css';

const defaultMenuItems = [
  {
    label: 'Super nómina (nómina padre)',
    key: 'item-1',
    icon: <StarFilled style={{ fontSize: '18px' }} />,
    disabled: true,
  },
  {
    label: 'Nómina hija',
    key: 'item-2',
    icon: <StarFilled style={{ fontSize: '14px' }} />,
    disabled: true,
  },
];

interface Props {
  payingCompaniesState: PayingCompaniesState;
  clientState: ClientState;
  dispatchFetchAllData: (clientId: string) => void;
}

const Payrolls = ({
  clientState,
  payingCompaniesState,
  dispatchFetchAllData,
}: Props) => {
  const { id = '' } = useParams();
  const { payrollForm, state, methods, fetchPayrolls } = usePayroll();

  const {
    payrolls,
    menuItems,
    menuSelected,
    payrollFlags,
    selectedPayroll,
    isLoadingPayrolls,
  } = state;

  const { onSelectMenuPayroll, onChangeSelectedPayroll } = methods;

  const dispersingCompany = payingCompaniesState.data.find(
    (obj) => obj._id === selectedPayroll?.dispersing_company
  );

  const billingCompany = payingCompaniesState.data.find(
    (obj) => obj._id === selectedPayroll?.billing_company
  );

  // filter companies that the client has configured
  const clientPayingCompanies = payingCompaniesState.data.filter(
    (obj) =>
      clientState.data?.payingCompanies &&
      clientState.data.payingCompanies.includes(obj._id)
  );

  const highDirectionPayingCompanies = clientPayingCompanies.filter(
    //include current billingCompany for <select>
    (i) => i._id === billingCompany?._id || i?.is_high_direction
  );

  const billingCompanyOptions = payrollFlags.isHighDirection
    ? highDirectionPayingCompanies
    : clientPayingCompanies;

  // assign new DispersingCompanyNOM035 if it does not exist
  const setDispersingCompanyNOM035 = useCallback(() => {
    if (!selectedPayroll) return;

    const { dispersing_company, dispersing_company_nom035, is_pension_base } =
      selectedPayroll;

    if (dispersing_company && !dispersing_company_nom035 && !is_pension_base) {
      const newDispersingCompanyNom035 = payingCompaniesState.data.find(
        (i) => i._id === dispersingCompany?.linked_nom035_society
      );

      if (newDispersingCompanyNom035) {
        payrollForm.setFieldValue(
          'dispersing_company_nom035',
          newDispersingCompanyNom035._id
        );
      }
    }
  }, [
    payrollForm,
    selectedPayroll,
    dispersingCompany,
    payingCompaniesState.data,
  ]);

  React.useEffect(() => {
    setDispersingCompanyNOM035();
  }, [setDispersingCompanyNOM035]);

  React.useEffect(() => {
    fetchPayrolls(id);
  }, [fetchPayrolls, id]);

  React.useEffect(() => {
    dispatchFetchAllData(id);
  }, [dispatchFetchAllData, id]);

  return (
    <div>
      <Spin spinning={isLoadingPayrolls}>
        <Row>
          <Col span={5}>
            <div style={{ position: 'sticky', top: 95 }}>
              <div className="payroll-menu-scroll">
                {isLoadingPayrolls ? (
                  <Skeleton
                    paragraph={{
                      rows: 10,
                    }}
                  />
                ) : (
                  <Menu
                    mode="inline"
                    items={[...defaultMenuItems, ...menuItems]}
                    onSelect={onSelectMenuPayroll}
                    selectedKeys={menuSelected}
                    className="payroll-menu"
                  />
                )}
              </div>
            </div>
          </Col>
          <Col span={19}>
            <div className="pl-3">
              {isLoadingPayrolls ? (
                <Skeleton
                  paragraph={{
                    rows: 18,
                  }}
                />
              ) : payrolls.length ? (
                <Form
                  form={payrollForm}
                  layout="vertical"
                  className="form-disabled"
                >
                  <General
                    selectedPayroll={selectedPayroll}
                    isPensionBase={payrollFlags.isPensionBase}
                    isSuperPayroll={payrollFlags.isSuperPayroll}
                  />
                  <ConditionalRender condition={!payrollFlags.isSuperPayroll}>
                    <WhoAbsorbsConcepts />
                    <PerceptionModifiers selectedPayroll={selectedPayroll} />
                    <Companies
                      selectedPayroll={selectedPayroll}
                      clientPayingCompanies={clientPayingCompanies}
                      billingCompanyOptions={billingCompanyOptions}
                    />
                    <CommissionAgents
                      selectedPayroll={selectedPayroll}
                      onChangeSelectedPayroll={onChangeSelectedPayroll}
                    />
                    <Billing
                      selectedPayroll={selectedPayroll}
                      onChangeSelectedPayroll={onChangeSelectedPayroll}
                    />
                    <Banks
                      selectedPayroll={selectedPayroll}
                      onChangeSelectedPayroll={onChangeSelectedPayroll}
                    />
                    <OperationalContacts
                      selectedPayroll={selectedPayroll}
                      onChangeSelectedPayroll={onChangeSelectedPayroll}
                    />
                  </ConditionalRender>
                  <Responsibles isSuperPayroll={payrollFlags.isSuperPayroll} />
                  <ConditionalRender
                    condition={
                      !payrollFlags.isSuperPayroll &&
                      payrollFlags.hasSYSNamespace
                    }
                  >
                    <VariableConcepts />
                  </ConditionalRender>
                </Form>
              ) : (
                <Empty description="No hay nóminas para mostrar" />
              )}
            </div>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export const ConditionalRender = ({
  condition,
  children,
}: {
  condition: boolean;
  children: React.ReactNode;
}) => {
  return condition ? <>{children}</> : null;
};

const mapStateToProps = (state: any) => {
  return {
    clientState: state?.clients?.client,
    payingCompaniesState: state.payingCompanies,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    ...bindActionCreators(
      {
        dispatchFetchAllData: (clientId: string) => {
          return () => {
            dispatch(fetchHolidays());
            dispatch(fetchWorks(clientId));
            dispatch(fetchPayingCompanies());
            dispatch(fetchPayrollFrequencies());
            dispatch(fetchNoministas());
            dispatch(fetchAdc());
            dispatch(fetchDispersadores());
            dispatch(fetchComisionistas());
            dispatch(fetchCfdis());
            dispatch(fetchPaymentMethods());
            dispatch(fetchModifierTypes());
            // dispatch(fetchSocieties());
          };
        },
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Payrolls);
