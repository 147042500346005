const dev = {
  // API_ENDPOINT_URL: 'http://127.0.0.1:5001/levita-app/us-central1',
  ENV: 'dev',
  API_ENDPOINT_URL: 'https://mini.levita.app/api',
  // API_ENDPOINT_URL: 'http://localhost:5002/api',
};

const prod = {
  // API_ENDPOINT_URL: '/api',
  API_ENDPOINT_URL: 'https://mini.levita.app/api',
  ENV: 'prod',
};

const test = {
  API_ENDPOINT_URL: '/api',
  ENV: 'test',
};

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return dev;
    case 'production':
      return prod;
    case 'test':
      return test;
    default:
      break;
  }
};

export const env = getEnv();
export const getPath = (prodPath, devPath) =>
  env.ENV === 'prod' ? prodPath : devPath;
