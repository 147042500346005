import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface ModalLevita {
  name: string;
  open: boolean;
  disabled: false;
}

export interface ModalActions {
  open: (name: string) => void;
  close: () => void;
}

export interface LevitaState {
  modal: ModalLevita;
}

const initialState: LevitaState = {
  modal: {
    name: '',
    open: false,
    disabled: false,
  },
};

export const payingCompaniesSlice = createSlice({
  name: 'payingCompanies',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<string>) => {
      state.modal.name = action.payload;
      state.modal.open = true;
      state.modal.disabled = false;
    },
    closeModal: (state) => {
      state.modal = initialState.modal;
    },
  },
  extraReducers: (builder) => {},
});

export const { openModal, closeModal } = payingCompaniesSlice.actions;

export default payingCompaniesSlice.reducer;
