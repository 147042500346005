import React from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { ColumnsType } from 'antd/lib/table';

import { env, getPath } from 'configs/EnvironmentConfig';
import { ClientOffice } from 'interfaces';
import { Alert } from 'utils';

const tableColumns: ColumnsType<ClientOffice> = [
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Manager',
    dataIndex: 'manager',
    key: 'manager',
  },
  {
    title: 'Activo',
    dataIndex: 'active',
    key: 'active',
    render(value: boolean) {
      return value ? 'Si' : 'No';
    },
  },
];

const RESOURCES = {
  CLIENT_OFFICES: getPath('client/offices', 'offices'),
};

export const getClientOffices = (client: string) =>
  axios
    .get<ClientOffice[]>(
      `${env?.API_ENDPOINT_URL}/${RESOURCES.CLIENT_OFFICES}`,
      {
        params: { client },
      }
    )
    .then((resp) => resp.data);

const useBranchOffices = () => {
  const { id = '' } = useParams();

  const [isLoading, setIsLoading] = React.useState(true);
  const [branchOffices, setBranchOffices] = React.useState<ClientOffice[]>([]);

  const fetchBranchOffices = React.useCallback(async () => {
    try {
      const resp = await getClientOffices(id);
      setBranchOffices(resp);
    } catch (err: any) {
      Alert.error(err, 'Error al obtener ClientOffices');
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  React.useEffect(() => {
    fetchBranchOffices();
  }, [fetchBranchOffices]);

  return {
    state: {
      isLoading,
      tableColumns,
      branchOffices,
      tablePagination: { hideOnSinglePage: true },
    },
  };
};

export default useBranchOffices;
