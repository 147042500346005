import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import axios from 'axios';
import { env, getPath } from 'configs/EnvironmentConfig';

export interface GroupEntity {
  _id: string;
  deleted_at?: string;
  created_at: string;
  updated_at: string;
  name: string;
}

export interface GroupEntities {
  isLoading: boolean;
  data: GroupEntity[];
}

interface InitialState {
  groupEntities: GroupEntities;
}

const initialState: InitialState = {
  groupEntities: {
    isLoading: false,
    data: [],
  },
};

const RESOURCE = getPath('groupEntities', 'groupEntities');

export const fetchGroupEntities = createAsyncThunk(
  'clients/fetchGroupEntities',
  (_, { getState }) => {
    const {
      clients: { groupEntities },
    } = getState() as {
      clients: InitialState;
    };

    if (groupEntities.data.length) {
      return groupEntities.data;
    }

    return axios
      .get<GroupEntity[]>(`${env?.API_ENDPOINT_URL}/${RESOURCE}`)
      .then((resp) => resp.data);
  }
);

export const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGroupEntities.pending, (state) => {
        state.groupEntities.isLoading = true;
      })
      .addCase(fetchGroupEntities.fulfilled, (state, action) => {
        state.groupEntities.isLoading = false;
        state.groupEntities.data = action.payload;
      })
      .addCase(fetchGroupEntities.rejected, (state, action) => {
        state.groupEntities.isLoading = false;
        notification.error({
          message: 'Error al obtener GroupEntities',
          description: action?.error?.message || 'Error desconocido',
        });
      });
  },
});

export default clientsSlice.reducer;
