import { Card, Form, Select, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';

import { CatalogsState } from 'store/slices/catalogs';
import { Payroll } from 'interfaces';

const { Title } = Typography;

interface Props {
  selectedPayroll: undefined | Payroll;
  onChangeSelectedPayroll: (key: string, value: any) => void;
}

const OperationalContacts = ({
  selectedPayroll,
  onChangeSelectedPayroll,
}: Props) => {
  return (
    <Card>
      <Title level={3}>Contactos Operativos</Title>
      <Form.Item label="Llenar Prenomina">
        <Select
          mode="multiple"
          value={selectedPayroll?.operational_contacts?.prenomina.map(
            (i) => i?.email
          )}
          options={selectedPayroll?.operationalContactsOptions?.prenomina.map(
            (i) => ({
              label: `${i?.name} (${i?.email})`,
              value: i?.email,
            })
          )}
          onChange={(value: string[]) => {
            const filtered =
              selectedPayroll?.operationalContactsOptions?.prenomina.filter(
                (i) => value.includes(i.email)
              );

            onChangeSelectedPayroll('operational_contacts.prenomina', filtered);
          }}
          placeholder="Selecciona el contacto"
        />
      </Form.Item>
      <Form.Item label="Autorizar Nomina">
        <Select
          mode="multiple"
          value={selectedPayroll?.operational_contacts?.nomina.map(
            (i) => i?.email
          )}
          options={selectedPayroll?.operationalContactsOptions?.nomina.map(
            (i) => ({
              label: `${i?.name} (${i?.email})`,
              value: i?.email,
            })
          )}
          onChange={(value: string[]) => {
            const filtered =
              selectedPayroll?.operationalContactsOptions?.nomina.filter((i) =>
                value.includes(i.email)
              );

            onChangeSelectedPayroll('operational_contacts.nomina', filtered);
          }}
          placeholder="Selecciona el contacto"
        />
      </Form.Item>
      <Form.Item label="Factura">
        <Select
          mode="multiple"
          value={selectedPayroll?.operational_contacts?.facturas.map(
            (i) => i?.email
          )}
          options={selectedPayroll?.operationalContactsOptions?.facturas.map(
            (i) => ({
              label: `${i?.name} (${i?.email})`,
              value: i?.email,
            })
          )}
          onChange={(value: string[]) => {
            const filtered =
              selectedPayroll?.operationalContactsOptions?.facturas.filter(
                (i) => value.includes(i.email)
              );

            onChangeSelectedPayroll('operational_contacts.facturas', filtered);
          }}
          placeholder="Selecciona el contacto"
        />
      </Form.Item>
      <Form.Item label="Recibos de Nomina Timbrados">
        <Select
          mode="multiple"
          value={selectedPayroll?.operational_contacts?.recibos_nomina.map(
            (i) => i?.email
          )}
          options={selectedPayroll?.operationalContactsOptions?.recibos_nomina.map(
            (i) => ({
              label: `${i?.name} (${i?.email})`,
              value: i?.email,
            })
          )}
          onChange={(value: string[]) => {
            const filtered =
              selectedPayroll?.operationalContactsOptions?.recibos_nomina.filter(
                (i) => value.includes(i.email)
              );

            onChangeSelectedPayroll(
              'operational_contacts.recibos_nomina',
              filtered
            );
          }}
          placeholder="Selecciona el contacto"
        />
      </Form.Item>
    </Card>
  );
};

const mapStateToProps = (state: {
  catalogs: CatalogsState;
}): {
  catalogsState: CatalogsState;
} => {
  return {
    catalogsState: state.catalogs,
  };
};

export default connect(mapStateToProps)(OperationalContacts);
