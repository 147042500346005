import { Card, Form, Input, Radio, Select, Switch, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';

import { Payroll } from 'interfaces';
import { CatalogsState } from 'store/slices/catalogs';
import { ClientsState, WorksState } from 'store/interfaces';
import { ConditionalRender } from '..';

const { Paragraph } = Typography;

interface Props {
  isSuperPayroll: boolean;
  isPensionBase: boolean;
  selectedPayroll: undefined | Payroll;
  worksState: WorksState;
  catalogsState: CatalogsState;
}

const General = ({
  selectedPayroll,
  catalogsState,
  isPensionBase,
  worksState,
  isSuperPayroll,
}: Props) => {
  const { payrollSchemes, payrollFrequencies, frequencyTypes, holidays } =
    catalogsState;

  const defaultWorkMessageOption = {
    label: worksState.data.length
      ? 'Selecciona una obra'
      : 'No hay obras para este cliente',
    value: '',
    disabled: true,
  };

  const worksOptions = worksState.data.map((i) => ({
    label: i.name,
    value: i._id,
  }));

  return (
    <Card>
      <Form.Item label="ID" name="_id">
        <Paragraph className="mb-0" copyable>
          {selectedPayroll?._id}
        </Paragraph>
      </Form.Item>
      <Form.Item label="Código de nómina" name="key_str">
        <Input />
      </Form.Item>
      <Form.Item label="Nombre de la nómina" name="name">
        <Input />
      </Form.Item>
      <Form.Item label="Es Super-Nómina">
        <Input value={isSuperPayroll ? 'Sí' : 'No'} bordered={false} readOnly />
      </Form.Item>
      <Form.Item label="Esquema" name="taxSchema">
        <Select options={payrollSchemes} disabled={isSuperPayroll} />
      </Form.Item>

      <ConditionalRender condition={!isSuperPayroll}>
        {isPensionBase && (
          <Form.Item label="Configuración de Esquema" name="is_pension_base">
            <Radio.Group className="group">
              <Radio value={true}>{'SYS + PPPS'}</Radio>
              <Radio value={false}>{'SYS + NOM'} </Radio>
            </Radio.Group>
          </Form.Item>
        )}
      </ConditionalRender>

      <Form.Item
        label="Periodo de la nómina"
        name={payrollFrequencies.isLoading ? undefined : 'frequency'}
      >
        <Select
          placeholder={payrollFrequencies.isLoading && 'Cargando periodos...'}
          options={payrollFrequencies.data.map((i) => ({
            value: i._id,
            label: i.name,
          }))}
          loading={payrollFrequencies.isLoading}
          disabled={payrollFrequencies.isLoading || isSuperPayroll}
        />
      </Form.Item>

      <ConditionalRender condition={isSuperPayroll}>
        <Form.Item label="Nóminas hijas compatibles">
          <Select
            mode="multiple"
            value={selectedPayroll?.superPayrollChilds?.map((i) => i._id)}
            options={selectedPayroll?.superPayrollChilds?.map((i) => ({
              value: i._id,
              label: i.name,
            }))}
            disabled
          />
        </Form.Item>
      </ConditionalRender>

      <ConditionalRender condition={!isSuperPayroll}>
        <Form.Item label="Corte Especial" name="is_special_frequency">
          <Radio.Group className="group">
            <Radio value={true}>{'Sí'}</Radio>
            <Radio value={false}>{'No'} </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Frecuencia" name="frequency_type">
          <Select
            // opción vacía agregada
            options={[
              {
                value: 0,
                label: 'Seleccione una frecuencia',
                disabled: true,
              },
              ...frequencyTypes.map(({ label, value }) => ({
                value,
                label,
              })),
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Calcular periodo con base en"
          name="periodCalculation"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Calcular ISR con base en reglamento"
          name="isrCalculation"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item label="Prima vacacional" name="holidayBonus">
          <Input className="w-100" bordered={false} readOnly />
        </Form.Item>
        <Form.Item label="Aguinaldo" name="bonus">
          <Input className="w-100" bordered={false} readOnly />
        </Form.Item>
        <Form.Item label="Vacaciones" name="holidays">
          <Select
            options={holidays.data.map((i) => ({
              value: i._id,
              label: i.type,
            }))}
            loading={holidays.isLoading}
            disabled
          />
        </Form.Item>
        <Form.Item label="Obra" name="work_id">
          <Select
            options={[defaultWorkMessageOption, ...worksOptions]}
            loading={worksState.isLoading}
            disabled={!worksState.data.length}
            placeholder="Selecciona una obra"
          />
        </Form.Item>
        <Form.Item
          label="Envió nomina plana"
          name="delivery_day"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      </ConditionalRender>
    </Card>
  );
};

const mapStateToProps = (state: {
  catalogs: CatalogsState;
  clients: ClientsState;
}): {
  catalogsState: CatalogsState;
  worksState: WorksState;
} => {
  return {
    catalogsState: state.catalogs,
    worksState: state?.clients?.works,
  };
};

export default connect(mapStateToProps)(General);
