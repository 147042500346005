import { Card, Form, Select, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { CatalogsState } from 'store/slices/catalogs';

const { Title } = Typography;

interface Props {
  catalogsState: CatalogsState;
}

const WhoAbsorbsConcepts = ({ catalogsState }: Props) => {
  const { payerTypes } = catalogsState;

  return (
    <Card>
      <Title level={3}>Quién absorbe conceptos</Title>
      <Form.Item
        label="IMSS"
        name={['calculation_settings', 'imss', 'default_payer']}
      >
        <Select options={payerTypes} />
      </Form.Item>
      <Form.Item
        label="IVA"
        name={['calculation_settings', 'iva', 'default_payer']}
      >
        <Select options={payerTypes} />
      </Form.Item>
      <Form.Item
        label="Costo social"
        name={['calculation_settings', 'costo_social', 'default_payer']}
      >
        <Select options={payerTypes} />
      </Form.Item>
      <Form.Item
        label="ISR"
        name={['calculation_settings', 'isr', 'default_payer']}
      >
        <Select options={payerTypes} />
      </Form.Item>
      <Form.Item
        label="ISN"
        name={['calculation_settings', 'isn', 'default_payer']}
      >
        <Select options={payerTypes} />
      </Form.Item>
      <Form.Item
        label="Comisión"
        name={['calculation_settings', 'commission', 'default_payer']}
      >
        <Select options={payerTypes} />
      </Form.Item>
    </Card>
  );
};

const mapStateToProps = (state: {
  catalogs: CatalogsState;
}): {
  catalogsState: CatalogsState;
} => {
  return {
    catalogsState: state.catalogs,
  };
};

export default connect(mapStateToProps)(WhoAbsorbsConcepts);
