import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import axios from 'axios';
import { env, getPath } from 'configs/EnvironmentConfig';

export interface State {
  _id: string;
  updated_at: Date;
  name: string;
  isn_percentage: number;
  is_isn_retained_by_client: boolean;
  deleted_at: null;
  created_at: Date;
}

export interface Municipality {
  _id: string;
  border_area: boolean;
  deleted_at: null;
  created_at: Date;
  updated_at: Date;
  name: string;
  state: string;
}

export interface States {
  isLoading: boolean;
  data: State[];
}

export interface Municipalities {
  isLoading: boolean;
  data: Municipality[];
}

interface InitialState {
  states: States;
  municipalities: Municipalities;
}

const initialState: InitialState = {
  states: {
    isLoading: false,
    data: [],
  },
  municipalities: {
    isLoading: false,
    data: [],
  },
};

const RESOURCE = getPath('statesAndMunicipality', 'statesAndMunicipality');

export const fetchStates = createAsyncThunk(
  'statesAndMunicipality/states',
  (_, { getState }) => {
    const {
      statesAndMunicipality: { states },
    } = getState() as {
      statesAndMunicipality: InitialState;
    };

    if (states.data.length) {
      return states.data;
    }

    return axios
      .get<State[]>(`${env?.API_ENDPOINT_URL}/${RESOURCE}`, {
        params: {
          filterBy: 'state',
        },
      })
      .then((resp) => resp.data);
  }
);

export const fetchMunicipalities = createAsyncThunk(
  'statesAndMunicipality/municipalities',
  (stateId: string, { getState }) => {
    if (!stateId) return [];

    return axios
      .get<Municipality[]>(`${env?.API_ENDPOINT_URL}/${RESOURCE}`, {
        params: {
          filterBy: 'municipality',
          stateId,
        },
      })
      .then((resp) => resp.data);
  }
);

export const statesAndMunicipalitySlice = createSlice({
  name: 'statesAndMunicipality',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStates.pending, (state) => {
        state.states.isLoading = true;
      })
      .addCase(fetchStates.fulfilled, (state, action) => {
        state.states.isLoading = false;
        state.states.data = action.payload;
      })
      .addCase(fetchStates.rejected, (state, action) => {
        state.states.isLoading = false;
        notification.error({
          message: 'Error al obtener States',
          description: action?.error?.message || 'Error desconocido',
        });
      })
      .addCase(fetchMunicipalities.pending, (state) => {
        state.municipalities.isLoading = true;
      })
      .addCase(fetchMunicipalities.fulfilled, (state, action) => {
        state.municipalities.isLoading = false;
        state.municipalities.data = action.payload;
      })
      .addCase(fetchMunicipalities.rejected, (state, action) => {
        state.municipalities.isLoading = false;
        notification.error({
          message: 'Error al obtener Municipalities',
          description: action?.error?.message || 'Error desconocido',
        });
      });
  },
});

export default statesAndMunicipalitySlice.reducer;
