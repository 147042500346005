import React from 'react';
import { connect } from 'react-redux';
import { Spin, Typography } from 'antd';

import { ModifierType } from 'interfaces';
import { CatalogsState } from 'store/slices/catalogs';
import { ModifierTypeEnum } from '../../payroll/components/PerceptionModifiers';
import { ClientState, ClientsState } from 'store/interfaces';

const { Title, Paragraph } = Typography;

interface Props {
  catalogsState: CatalogsState;
  clientState: ClientState;
}

const Services = ({ catalogsState, clientState }: Props) => {
  const { modifierTypes } = catalogsState;

  const clientModifierTypesId = clientState.data?.modifier_types_id || [];
  const clientModifierTypes = clientState.data?.modifier_types || [];

  const validModifierTypesIds = modifierTypes.data.filter(
    (i) => i._id && clientModifierTypesId.includes(i._id)
  );

  const validModifierTypes = validModifierTypesIds.filter((i) =>
    clientModifierTypes.includes(i._id)
  );

  return (
    <div>
      <Title level={3} className="pb-2">
        Modificadores de percepción
      </Title>
      <Spin spinning={modifierTypes.isLoading}>
        <Title level={4}>Seguros</Title>
        <RenderModifiers
          modifiers={validModifierTypes}
          type={ModifierTypeEnum.Seguros}
        />

        <Title level={4}>Financieros</Title>
        <RenderModifiers
          modifiers={validModifierTypes}
          type={ModifierTypeEnum.Financieros}
        />

        <Title level={4}>Adicionales</Title>
        <RenderModifiers
          modifiers={validModifierTypes}
          type={ModifierTypeEnum.Adicionales}
        />
      </Spin>
    </div>
  );
};

const RenderModifiers = ({
  modifiers,
  type,
}: {
  modifiers: ModifierType[];
  type: number;
}) => {
  const filteredModifiers = modifiers.filter((i) => i.type === type);

  const modifierElements = filteredModifiers.map((i) => (
    <div className="pb-2" key={i.name}>
      <Paragraph className="mb-0">{i.name}</Paragraph>
    </div>
  ));

  return (
    <div className="pb-4">
      {modifierElements.length ? (
        modifierElements
      ) : (
        <p>No se encontraron modificadores de percepción</p>
      )}
    </div>
  );
};

const mapStateToProps = (state: {
  catalogs: CatalogsState;
  clients: ClientsState;
}): {
  catalogsState: CatalogsState;
  clientState: ClientState;
} => {
  return {
    catalogsState: state.catalogs,
    clientState: state.clients.client,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Services);
