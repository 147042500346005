import { Card, DatePicker, Form, Select, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { CatalogsState } from 'store/slices/catalogs';

import Flex from 'components/shared-components/Flex';
import { ConditionalRender } from '..';

const { Title } = Typography;

interface Props {
  isSuperPayroll: boolean;
  catalogsState: CatalogsState;
}

const Responsibles = ({ isSuperPayroll, catalogsState }: Props) => {
  const { noministas, adc, dispersadores } = catalogsState;

  return (
    <Card>
      <Title level={3}>Responsables</Title>
      <Form.Item
        label="Nominista"
        name={noministas.isLoading ? undefined : 'nominista'}
      >
        <Select
          placeholder={
            noministas.isLoading
              ? 'Cargando noministas...'
              : 'Selecciona un nominista'
          }
          options={[
            {
              label: 'Selecciona un nominista',
              value: '',
              disabled: true,
            },
            ...noministas.data.map(({ id, fullName, job }) => ({
              label: `${fullName} - ${job}`,
              value: id,
            })),
            ...adc.data.map(({ id, fullName, job }) => ({
              label: `${fullName} - ${job}`,
              value: id,
            })),
          ]}
          loading={noministas.isLoading}
          disabled={noministas.isLoading}
        />
      </Form.Item>
      <Form.Item label="ADC" name={adc.isLoading ? undefined : 'adc'}>
        <Select
          placeholder={adc.isLoading ? 'Cargando adc...' : 'Selecciona un ADC'}
          options={[
            {
              label: 'Selecciona un ADC',
              value: '',
              disabled: true,
            },
            ...adc.data.map(({ id, fullName, job }) => ({
              label: `${fullName} - ${job}`,
              value: id,
            })),
          ]}
          loading={adc.isLoading}
          disabled={adc.isLoading}
        />
      </Form.Item>
      <Form.Item
        label="Dispersador"
        name={dispersadores.isLoading ? undefined : 'dispersador'}
      >
        <Select
          placeholder={
            dispersadores.isLoading
              ? 'Cargando dispersadores...'
              : 'Selecciona un dispersador'
          }
          options={[
            {
              label: 'Selecciona un dispersador',
              value: '',
              disabled: true,
            },
            ...dispersadores.data.map(({ id, fullName, job }) => ({
              label: `${fullName} - ${job}`,
              value: id,
            })),
          ]}
          loading={dispersadores.isLoading}
          disabled={dispersadores.isLoading}
        />
      </Form.Item>
      <ConditionalRender condition={!isSuperPayroll}>
        <Flex justifyContent="around">
          <Form.Item label="Fecha de inicio contrato" name="contract_start_at">
            <DatePicker format="DD MMM YYYY" />
          </Form.Item>
          <Form.Item label="Fecha de termino contrato" name="contract_end_at">
            <DatePicker format="DD MMM YYYY" />
          </Form.Item>
        </Flex>
      </ConditionalRender>
    </Card>
  );
};

const mapStateToProps = (state: {
  catalogs: CatalogsState;
}): {
  catalogsState: CatalogsState;
} => {
  return {
    catalogsState: state.catalogs,
  };
};

export default connect(mapStateToProps)(Responsibles);
