import { Card, Form, InputNumber, Row, Col, Select, Typography } from 'antd';
import { CommissionAgent, Payroll } from 'interfaces';
import React from 'react';
import { connect } from 'react-redux';
import { CatalogsState } from 'store/slices/catalogs';

const { Title } = Typography;

interface Props {
  selectedPayroll: undefined | Payroll;
  catalogsState: CatalogsState;
  onChangeSelectedPayroll: (key: string, value: any) => void;
}

const Comisionistas = ({
  catalogsState,
  selectedPayroll,
  onChangeSelectedPayroll,
}: Props) => {
  const { comisionistas } = catalogsState;

  const [selectedCommissionAgents, setSelectedCommissionAgents] =
    React.useState<string[]>([]);

  const getFullName = (id: string) => {
    const comisionista = comisionistas.data.find((i) => i.id === id);

    if (!comisionista) return '';

    return `${comisionista?.name} ${comisionista?.firstLastName} ${comisionista?.secondLastName}`;
  };

  const onSelectComisionist = (option: string) => {
    const newComisionist: CommissionAgent = {
      employee_id: option,
      percentage: 0,
      type: 'Gerente de Ventas',
    };

    const comisionistsUpdated = [
      ...(selectedPayroll?.commission_agents
        ? selectedPayroll?.commission_agents
        : []),
      newComisionist,
    ];

    onChangeSelectedPayroll('commission_agents', comisionistsUpdated);
  };

  const onDeselectComisionist = (option: string) => {
    const comisionistsUpdated = selectedPayroll!.commission_agents!.filter(
      (i) => i.employee_id !== option
    );

    onChangeSelectedPayroll('commission_agents', comisionistsUpdated);
  };

  React.useEffect(() => {
    if (!selectedPayroll?.commission_agents) return;

    const selectedAggents = selectedPayroll?.commission_agents?.map(
      (i) => i.employee_id
    );

    setSelectedCommissionAgents(selectedAggents);
  }, [selectedPayroll]);

  return (
    <Card>
      <Title level={3}>Comisionistas</Title>
      <Form.Item>
        <Select
          mode="multiple"
          onSelect={onSelectComisionist}
          onDeselect={onDeselectComisionist}
          value={selectedCommissionAgents}
          loading={comisionistas.isLoading}
          disabled={comisionistas.isLoading}
          options={comisionistas.data.map((i) => ({
            label: `${i.name} ${i.firstLastName} ${i.secondLastName}`,
            value: i.id,
          }))}
          placeholder={
            comisionistas.isLoading
              ? 'Cargando comisionistas...'
              : 'Selecciona un comisionista'
          }
        />
      </Form.Item>
      {selectedPayroll?.commission_agents?.map((i, index) => (
        <Row key={i.employee_id}>
          <Col span={8}>
            <p className="pr-2">{getFullName(i.employee_id)}</p>
          </Col>
          <Col span={8} className="pr-3">
            <Select
              options={[
                { value: 'Gerente de Ventas', label: 'Gerente de Ventas' },
                { value: 'Director de Ventas', label: 'Director de Ventas' },
                { value: 'Vendedor', label: 'Vendedor' },
                { value: 'Referidor', label: 'Referidor' },
              ]}
              value={i.type}
              className="w-100"
              onChange={(value: string) => {
                onChangeSelectedPayroll(
                  `commission_agents[${index}].type`,
                  value
                );
              }}
            />
          </Col>
          <Col span={8}>
            <InputNumber
              className="w-100"
              controls={false}
              addonAfter="%"
              value={i.percentage}
              min={0}
              max={100}
              onChange={(value: number | null) => {
                onChangeSelectedPayroll(
                  `commission_agents[${index}].percentage`,
                  value === null ? 0 : value
                );
              }}
            />
          </Col>
        </Row>
      ))}
    </Card>
  );
};

const mapStateToProps = (state: {
  catalogs: CatalogsState;
}): { catalogsState: CatalogsState } => {
  return {
    catalogsState: state.catalogs,
  };
};

export default connect(mapStateToProps)(Comisionistas);
