import React from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Card, Table, Typography, notification } from 'antd';

import { env, getPath } from 'configs/EnvironmentConfig';

const columns = [
  {
    title: 'Nombre del puesto',
    dataIndex: 'job',
    key: 'job',
  },
  {
    title: 'Personas en el puesto',
    dataIndex: 'count',
    key: 'count',
  },
];

const RESOURCE = getPath('client/jobs', 'clientJobs');

const getJobs = (client: string) =>
  axios
    .get<Job[]>(`${env?.API_ENDPOINT_URL}/${RESOURCE}`, {
      params: { client },
    })
    .then((resp) => resp.data);

interface Job {
  job: string;
  count: number;
}

const Jobs = () => {
  const { id = '' } = useParams();

  const [jobs, setJobs] = React.useState<Job[]>([]);
  const [isLoadingJobs, setIsLoadingJobs] = React.useState(true);

  const fetchJobs = React.useCallback(async (id: string) => {
    setIsLoadingJobs(true);
    try {
      const jobResp = await getJobs(id);

      setJobs(jobResp);
    } catch (error: any) {
      notification.error({
        message: 'Error al obtener Jobs',
        description: error?.message || 'Error desconocido',
      });
    } finally {
      setIsLoadingJobs(false);
    }
  }, []);

  React.useEffect(() => {
    fetchJobs(id);
  }, [fetchJobs, id]);

  return (
    <>
      <Card>
        <Typography.Text>
          Se enlistan y cuentan los puestos que hay en el cliente, es una vista
          de consulta
        </Typography.Text>
        <Table
          className="pt-4"
          dataSource={jobs}
          columns={columns}
          pagination={{ hideOnSinglePage: true }}
          loading={isLoadingJobs}
        />
      </Card>
    </>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Jobs);
