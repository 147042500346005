import React from 'react';
import { Form, Input, Modal } from 'antd';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  ModalActions,
  ModalLevita,
  closeModal,
  openModal,
} from 'store/slices/levitaSlice';

export const ADD_CONTACT_MODAL = 'ADD_CONTACT_CLIENT';

const isModalOpen = (modalState: ModalLevita) =>
  modalState.open && modalState.name === ADD_CONTACT_MODAL;

interface Props {
  modalState: ModalLevita;
  modal: ModalActions;
  callback: () => void;
}

const AddContact = ({ modal, modalState, callback }: Props) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = React.useState(false);

  const handleOk = async () => {
    const formFields = await form.validateFields();

    if (formFields?.errorFields) return;

    console.log({ formFields });

    setIsLoading(true);

    try {
      //fake fetch action
      await new Promise((resolve) => setTimeout(resolve, 3000));

      callback();
      modal.close();
      form.resetFields();
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => modal.close();

  return (
    <Modal
      open={isModalOpen(modalState)}
      okText={isLoading ? 'Guardando' : 'Guardar'}
      title="Crear un nuevo departamento"
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={isLoading}
    >
      <Form layout="vertical" form={form} disabled={isLoading}>
        <Form.Item
          label="Nombre"
          name="name"
          rules={[{ required: true, message: 'Ingresa tu nombre' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: 'Ingresa tu email',
            },
            {
              type: 'email',
              message: 'Por favor ingresa un correo electrónico válido',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Domicilio" name="address">
          <Input />
        </Form.Item>
        <Form.Item label="Teléfono" name="phone">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state: any) => {
  return {
    modalState: state.levita.modal,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    modal: bindActionCreators(
      {
        open: openModal,
        close: closeModal,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddContact);
