const FirebaseConfig = {
  apiKey: 'AIzaSyCcrd4VufC3FJh4_UqaXAfAo1vL5f-jjHg',
  authDomain: 'levita-app.firebaseapp.com',
  projectId: 'levita-app',
  storageBucket: 'levita-app.appspot.com',
  messagingSenderId: '738848712402',
  appId: '1:738848712402:web:344852aeaebeef4e6fef0b',
  measurementId: 'G-NCB0Y0NGS2',
};

export default FirebaseConfig;
