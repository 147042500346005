import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  FrequencyType,
  PayerType,
  PayrollScheme,
  frequencyTypes,
  payerTypes,
  payrollSchemes,
} from './static';
import {
  AdcState,
  CfdisState,
  ComisionistasState,
  DispersadoresState,
  HolidayState,
  ModifierTypesState,
  NoministasState,
  PaymentMethodState,
  PeriodFrequencyState,
  SocietiesState,
} from './definitions';
import {
  fetchAdc,
  fetchCfdis,
  fetchComisionistas,
  fetchDispersadores,
  fetchHolidays,
  fetchModifierTypes,
  fetchNoministas,
  fetchPaymentMethods,
  fetchPayrollFrequencies,
  fetchSocieties,
} from './httpActions';
import { notification } from 'antd';

export interface CatalogsState {
  payrollSchemes: PayrollScheme[];
  frequencyTypes: FrequencyType[];
  payerTypes: PayerType[];
  payrollFrequencies: PeriodFrequencyState;
  holidays: HolidayState;
  noministas: NoministasState;
  adc: AdcState;
  dispersadores: DispersadoresState;
  comisionistas: ComisionistasState;
  cfdis: CfdisState;
  paymentMethods: PaymentMethodState;
  modifierTypes: ModifierTypesState;
  societies: SocietiesState;
}

const initialState: CatalogsState = {
  payrollSchemes,
  frequencyTypes,
  payerTypes,
  payrollFrequencies: {
    isLoading: false,
    data: [],
  },
  holidays: {
    data: [],
    isLoading: false,
  },
  noministas: {
    data: [],
    isLoading: false,
  },
  adc: {
    data: [],
    isLoading: false,
  },
  dispersadores: {
    data: [],
    isLoading: false,
  },
  comisionistas: {
    data: [],
    isLoading: false,
  },
  cfdis: {
    data: [],
    isLoading: false,
  },
  paymentMethods: {
    data: [],
    isLoading: false,
  },
  modifierTypes: {
    data: [],
    isLoading: false,
  },
  societies: {
    data: [],
    isLoading: false,
  },
};

export const catalogsSlice = createSlice({
  name: 'catalogs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPayrollFrequencies.pending, (state) => {
        state.payrollFrequencies.isLoading = true;
      })
      .addCase(fetchPayrollFrequencies.fulfilled, (state, action) => {
        state.payrollFrequencies.isLoading = false;
        state.payrollFrequencies.data = action.payload;
      })
      .addCase(fetchPayrollFrequencies.rejected, (state, action) => {
        state.payrollFrequencies.isLoading = false;
        notification.error({
          message: 'Error al obtener GroupEntities',
          description: action?.error?.message || 'Error desconocido',
        });
      })
      .addCase(fetchHolidays.pending, (state) => {
        state.holidays.isLoading = true;
      })
      .addCase(fetchHolidays.fulfilled, (state, action) => {
        state.holidays.isLoading = false;
        state.holidays.data = action.payload;
      })
      .addCase(fetchHolidays.rejected, (state, action) => {
        state.holidays.isLoading = false;
        notification.error({
          message: 'Error al obtener Holidays',
          description: action?.error?.message || 'Error desconocido',
        });
      })
      .addCase(fetchNoministas.pending, (state) => {
        state.noministas.isLoading = true;
      })
      .addCase(fetchNoministas.fulfilled, (state, action) => {
        state.noministas.isLoading = false;
        state.noministas.data = action.payload;
      })
      .addCase(fetchNoministas.rejected, (state, action) => {
        state.noministas.isLoading = false;
        notification.error({
          message: 'Error al obtener Noministas',
          description: action?.error?.message || 'Error desconocido',
        });
      })
      .addCase(fetchAdc.pending, (state) => {
        state.adc.isLoading = true;
      })
      .addCase(fetchAdc.fulfilled, (state, action) => {
        state.adc.isLoading = false;
        state.adc.data = action.payload;
      })
      .addCase(fetchAdc.rejected, (state, action) => {
        state.adc.isLoading = false;
        notification.error({
          message: 'Error al obtener Noministas',
          description: action?.error?.message || 'Error desconocido',
        });
      })
      .addCase(fetchDispersadores.pending, (state) => {
        state.dispersadores.isLoading = true;
      })
      .addCase(fetchDispersadores.fulfilled, (state, action) => {
        state.dispersadores.isLoading = false;
        state.dispersadores.data = action.payload;
      })
      .addCase(fetchDispersadores.rejected, (state, action) => {
        state.dispersadores.isLoading = false;
        notification.error({
          message: 'Error al obtener Noministas',
          description: action?.error?.message || 'Error desconocido',
        });
      })
      .addCase(fetchComisionistas.pending, (state) => {
        state.comisionistas.isLoading = true;
      })
      .addCase(fetchComisionistas.fulfilled, (state, action) => {
        state.comisionistas.isLoading = false;
        state.comisionistas.data = action.payload;
      })
      .addCase(fetchComisionistas.rejected, (state, action) => {
        state.comisionistas.isLoading = false;
        notification.error({
          message: 'Error al obtener Comisionistas',
          description: action?.error?.message || 'Error desconocido',
        });
      })
      .addCase(fetchCfdis.pending, (state) => {
        state.cfdis.isLoading = true;
      })
      .addCase(fetchCfdis.fulfilled, (state, action) => {
        state.cfdis.isLoading = false;
        state.cfdis.data = action.payload;
      })
      .addCase(fetchCfdis.rejected, (state, action) => {
        state.cfdis.isLoading = false;
        notification.error({
          message: 'Error al obtener Cfdis',
          description: action?.error?.message || 'Error desconocido',
        });
      })
      .addCase(fetchPaymentMethods.pending, (state) => {
        state.paymentMethods.isLoading = true;
      })
      .addCase(fetchPaymentMethods.fulfilled, (state, action) => {
        state.paymentMethods.isLoading = false;
        state.paymentMethods.data = action.payload;
      })
      .addCase(fetchPaymentMethods.rejected, (state, action) => {
        state.paymentMethods.isLoading = false;
        notification.error({
          message: 'Error al obtener PaymentMethods',
          description: action?.error?.message || 'Error desconocido',
        });
      })
      .addCase(fetchModifierTypes.pending, (state) => {
        state.modifierTypes.isLoading = true;
      })
      .addCase(fetchModifierTypes.fulfilled, (state, action) => {
        state.modifierTypes.isLoading = false;
        state.modifierTypes.data = action.payload;
      })
      .addCase(fetchModifierTypes.rejected, (state, action) => {
        state.modifierTypes.isLoading = false;
        notification.error({
          message: 'Error al obtener PaymentMethods',
          description: action?.error?.message || 'Error desconocido',
        });
      })
      .addCase(fetchSocieties.pending, (state) => {
        state.societies.isLoading = true;
      })
      .addCase(fetchSocieties.fulfilled, (state, action) => {
        state.societies.isLoading = false;
        state.societies.data = action.payload;
      })
      .addCase(fetchSocieties.rejected, (state, action) => {
        state.societies.isLoading = false;
        notification.error({
          message: 'Error al obtener Societies',
          description: action?.error?.message || 'Error desconocido',
        });
      });
  },
});

// export const { openModal, closeModal } = catalogsSlice.actions;

export default catalogsSlice.reducer;
