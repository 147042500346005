import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { env, getPath } from 'configs/EnvironmentConfig';
import { CatalogsState } from '.';
import {
  Cfdi,
  Holiday,
  ModifierType,
  PaymentMethod,
  PeriodFrequency,
  TSSociety,
  TSUser,
} from 'interfaces';
import { getToken } from 'utils/axios-request';

const RESOURCES = {
  PAYROLL_FREQUENCIES: getPath('payrollFrequencies', 'payrollFrequencies'),
  HOLIDAYS: getPath('holidays', 'holidays'),
  EMPLOYEES: getPath('users/search', 'searchUsers'),
  CFDIS: getPath('cfdis', 'cfdis'),
  PAYMENT_METHODS: getPath('paymentMethods', 'paymentMethods'),
  MODIFIER_TYPES: getPath('modifierTypes', 'modifierTypes'),
  SOCIETIES: getPath('societies/search', 'searchSocieties'),
};

export const fetchPayrollFrequencies = createAsyncThunk(
  'catalogs/payrollFrequencies',
  (_, { getState }) => {
    const {
      catalogs: { payrollFrequencies },
    } = getState() as {
      catalogs: CatalogsState;
    };

    if (payrollFrequencies.data.length) {
      return payrollFrequencies.data;
    }

    return axios
      .get<PeriodFrequency[]>(
        `${env?.API_ENDPOINT_URL}/${RESOURCES.PAYROLL_FREQUENCIES}`
      )
      .then((resp) => resp.data);
  }
);

export const fetchHolidays = createAsyncThunk(
  'catalogs/fetchHolidays',
  (_, { getState }) => {
    const {
      catalogs: { holidays },
    } = getState() as {
      catalogs: CatalogsState;
    };

    if (holidays.data.length) {
      return holidays.data;
    }

    return axios
      .get<Holiday[]>(`${env?.API_ENDPOINT_URL}/${RESOURCES.HOLIDAYS}`)
      .then((resp) => resp.data);
  }
);

enum Jobs {
  NOMINISTA = 'nominista',
  ADC = 'administrador de cuenta, adc',
  DISPERSADOR = 'dispersador, coordinador de dispersiones',
  COMISIONISTA = 'vendedor', // status: Comisionista
}

export const fetchNoministas = createAsyncThunk(
  'catalogs/fetchNoministas',
  async (_, { getState }) => {
    const {
      catalogs: { noministas },
    } = getState() as {
      catalogs: CatalogsState;
    };

    if (noministas.data.length) {
      return noministas.data;
    }

    const data = await axios
      .get(`${env?.API_ENDPOINT_URL}/${RESOURCES.EMPLOYEES}`, {
        params: { job: Jobs.NOMINISTA },
        headers: { ...getToken() },
      })
      .then((resp) => resp.data);

    const hits = data?.hits || [];

    return hits.map((nominist: any) => {
      return nominist.document;
    }) as TSUser[];
  }
);

export const fetchAdc = createAsyncThunk(
  'catalogs/fetchAdc',
  async (_, { getState }) => {
    const {
      catalogs: { adc },
    } = getState() as {
      catalogs: CatalogsState;
    };

    if (adc.data.length) {
      return adc.data;
    }

    const data = await axios
      .get(`${env?.API_ENDPOINT_URL}/${RESOURCES.EMPLOYEES}`, {
        params: { job: Jobs.ADC },
        headers: { ...getToken() },
      })
      .then((resp) => resp.data);

    const hits = data?.hits || [];

    return hits.map((nominist: any) => {
      return nominist.document;
    }) as TSUser[];
  }
);

export const fetchDispersadores = createAsyncThunk(
  'catalogs/fetchDispersadores',
  async (_, { getState }) => {
    const {
      catalogs: { dispersadores },
    } = getState() as {
      catalogs: CatalogsState;
    };

    if (dispersadores.data.length) {
      return dispersadores.data;
    }

    const data = await axios
      .get(`${env?.API_ENDPOINT_URL}/${RESOURCES.EMPLOYEES}`, {
        params: { job: Jobs.DISPERSADOR },
        headers: { ...getToken() },
      })
      .then((resp) => resp.data);

    const hits = data?.hits || [];

    return hits.map((nominist: any) => {
      return nominist.document;
    }) as TSUser[];
  }
);

export const fetchComisionistas = createAsyncThunk(
  'catalogs/fetchComisionistas',
  async (_, { getState }) => {
    const {
      catalogs: { comisionistas },
    } = getState() as {
      catalogs: CatalogsState;
    };

    if (comisionistas.data.length) {
      return comisionistas.data;
    }

    const data = await axios
      .get(`${env?.API_ENDPOINT_URL}/${RESOURCES.EMPLOYEES}`, {
        params: { status: Jobs.COMISIONISTA, limit: 20 },
        headers: { ...getToken() },
      })
      .then((resp) => resp.data);

    const hits = data?.hits || [];

    return hits
      .map((nominist: any) => {
        return nominist.document;
      })
      .filter((item: any) => item?.status === 'Comisionista') as TSUser[];
  }
);

export const fetchCfdis = createAsyncThunk(
  'catalogs/fetchCfdis',
  (_, { getState }) => {
    const {
      catalogs: { cfdis },
    } = getState() as {
      catalogs: CatalogsState;
    };

    if (cfdis.data.length) {
      return cfdis.data;
    }

    return axios
      .get<Cfdi[]>(`${env?.API_ENDPOINT_URL}/${RESOURCES.CFDIS}`)
      .then((resp) => resp.data);
  }
);

export const fetchPaymentMethods = createAsyncThunk(
  'catalogs/fetchPaymentMethods',
  (_, { getState }) => {
    const {
      catalogs: { paymentMethods },
    } = getState() as {
      catalogs: CatalogsState;
    };

    if (paymentMethods.data.length) {
      return paymentMethods.data;
    }

    return axios
      .get<PaymentMethod[]>(
        `${env?.API_ENDPOINT_URL}/${RESOURCES.PAYMENT_METHODS}`
      )
      .then((resp) => resp.data);
  }
);

export const fetchModifierTypes = createAsyncThunk(
  'catalogs/fetchModifierTypes',
  (_, { getState }) => {
    const {
      catalogs: { modifierTypes },
    } = getState() as {
      catalogs: CatalogsState;
    };

    if (modifierTypes.data.length) {
      return modifierTypes.data;
    }

    return axios
      .get<ModifierType[]>(
        `${env?.API_ENDPOINT_URL}/${RESOURCES.MODIFIER_TYPES}`
      )
      .then((resp) => resp.data);
  }
);

export const fetchSocieties = createAsyncThunk(
  'catalogs/fetchSocieties',
  async (_, { getState }) => {
    const {
      catalogs: { societies },
    } = getState() as {
      catalogs: CatalogsState;
    };

    if (societies.data.length) {
      return societies.data;
    }

    const data = await axios
      .get(`${env?.API_ENDPOINT_URL}/${RESOURCES.SOCIETIES}`, {
        headers: { ...getToken() },
      })
      .then((resp) => resp.data);

    const hits = data?.hits || [];

    return hits.map((society: any) => {
      return society.document;
    }) as TSSociety[];
  }
);
