import { Client } from 'interfaces';
import moment from 'moment';

//parse string dates to moment
export const parseClient = (client: Client) => {
  const { kardex } = client;
  const { acceptance, payment, operation } = kardex;

  return {
    ...client,
    kardex: {
      ...kardex,
      payment: {
        ...payment,
        dateFirstDisperssion: moment(payment?.dateFirstDisperssion).isValid()
          ? moment(payment.dateFirstDisperssion)
          : undefined,
        documents: moment(payment?.documents).isValid()
          ? moment(payment.documents)
          : undefined,
      },
      acceptance: {
        ...acceptance,
        date: moment(acceptance?.date).isValid()
          ? moment(acceptance.date)
          : undefined,
      },
      operation: {
        ...operation,
        registration: moment(operation?.registration).isValid()
          ? moment(operation?.registration)
          : undefined,
        imss: moment(operation?.imss).isValid()
          ? moment(operation.imss)
          : undefined,
        exit: moment(operation?.exit).isValid()
          ? moment(operation.exit)
          : undefined,
      },
    },
  };
};
