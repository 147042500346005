import { DatePicker, Form, Input, Switch } from 'antd';
import React from 'react';
import { datePickerStyles } from '.';

const Acceptance = () => {
  return (
    <div className="py-4">
      <Form.Item
        label="CEO"
        valuePropName="checked"
        name={['kardex', 'acceptance', 'static']}
      >
        <Switch />
      </Form.Item>
      <Form.Item
        label="Comercial"
        valuePropName="checked"
        name={['kardex', 'acceptance', 'commercial']}
      >
        <Switch />
      </Form.Item>
      <Form.Item
        label="Cliente"
        valuePropName="checked"
        name={['kardex', 'acceptance', 'customer']}
      >
        <Switch />
      </Form.Item>
      <Form.Item
        label="Operación"
        valuePropName="checked"
        name={['kardex', 'acceptance', 'operation']}
      >
        <Switch />
      </Form.Item>
      <Form.Item
        label="Fecha de aceptación"
        name={['kardex', 'acceptance', 'date']}
      >
        <DatePicker
          placeholder="Selecciona una fecha"
          style={datePickerStyles}
          format={'DD MMM YYYY'}
        />
      </Form.Item>
      <Form.Item
        label="Observaciones"
        name={['kardex', 'acceptance', 'observations']}
      >
        <Input />
      </Form.Item>
    </div>
  );
};

export default Acceptance;
