import {
  Alert,
  Badge,
  Button,
  Card,
  Form,
  Switch,
  Table,
  Typography,
} from 'antd';
import Flex from 'components/shared-components/Flex';
import React from 'react';
import { FileTextOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { CatalogsState } from 'store/slices/catalogs';
import { Invoicing, Payroll } from 'interfaces';
import { Dispatch, bindActionCreators } from 'redux';
import { ModalActions, closeModal, openModal } from 'store/slices/levitaSlice';
import AddBilling, { ADD_BILLING_MODAL } from '../../../modals/AddBilling';
import { PayingCompaniesState } from 'store/slices/payingCompaniesSlice';

const { Title, Text } = Typography;

interface Props {
  modal: ModalActions;
  catalogsState: CatalogsState;
  selectedPayroll: undefined | Payroll;
  payingCompaniesState: PayingCompaniesState;
  onChangeSelectedPayroll: (key: string, value: any) => void;
}

const Billing = ({
  modal,
  catalogsState,
  selectedPayroll,
  payingCompaniesState,
  onChangeSelectedPayroll,
}: Props) => {
  const form = Form.useFormInstance();
  const billingCompanyId = Form.useWatch<string>('billing_company', form);

  const [editingInvoice, setEditingInvoice] = React.useState<Invoicing>();

  const billingCompany = payingCompaniesState.data.find(
    (i) => i._id === billingCompanyId
  );

  const editingInvoiceConcepts = editingInvoice?.concepts
    ? editingInvoice.concepts
    : [];
  const billingCompanyConcepts = billingCompany?.invoice_concepts
    ? billingCompany.invoice_concepts
    : [];

  const onCreateInvoice = (invoicing: Invoicing) => {
    const updatedInvoicing = editingInvoice
      ? selectedPayroll!.invoicing.map((item) =>
          item.id === invoicing.id ? invoicing : item
        )
      : [...selectedPayroll!.invoicing, invoicing];

    onChangeSelectedPayroll('invoicing', updatedInvoicing);
  };

  const handleEditInvoice = (record: Invoicing) => {
    setEditingInvoice(record);
    modal.open(ADD_BILLING_MODAL);
  };

  const handleCreateInvoice = () => {
    setEditingInvoice(undefined);
    modal.open(ADD_BILLING_MODAL);
  };

  return (
    <>
      <Card>
        <Title level={3}>Facturación</Title>
        <Alert
          message="Se debe definir el porcentaje que se utilizará para generar la factura del total de la super nómina, el total no debe de exceder el 100%"
          type="info"
          showIcon
          className="mb-4"
        />
        {/* <Form.Item label="Porcentaje de la nómina hija">
          <Input />
        </Form.Item> */}
        <Alert
          message="Para editar la factura, de click sobre alguna de las facturas"
          type="info"
          showIcon
          className="mb-4"
        />
        <Flex justifyContent="around">
          <Form.Item
            label="Facturación especial"
            name="special_invoice"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label="Facturación verificada"
            name="has_verify_invoicing"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Flex>
        <Table
          columns={[
            {
              key: 'icon-doc',
              render: (_, record) => (
                <Badge
                  count={`${record.percentage}%`}
                  overflowCount={9999}
                  offset={[14, 0]}
                  style={{
                    backgroundColor: '#52c41a',
                    fontSize: '10px',
                  }}
                >
                  <FileTextOutlined style={{ fontSize: '26px' }} />
                </Badge>
              ),
            },
            {
              key: 'name',
              title: 'Nombre de la factura',
              dataIndex: 'name',
            },
            {
              key: 'conceptos',
              title: 'Conceptos de facturación',
              dataIndex: 'conceptos',
              align: 'center',
              render: (_, record) => (
                <>
                  <Text className="pr-2">{record.concepts?.length}</Text>
                  <Text strong style={{ color: 'rgb(82, 196, 26)' }}>
                    «{record.percentage}%»
                  </Text>
                </>
              ),
            },
          ]}
          dataSource={selectedPayroll?.invoicing}
          pagination={{ hideOnSinglePage: true }}
          onRow={(record) => ({
            onClick: () => handleEditInvoice(record),
            className: 'cursor-pointer',
          })}
          className="mb-4"
          rowKey="id"
        />
        <Flex justifyContent="center">
          <Button type="primary" onClick={handleCreateInvoice}>
            Agregar otra factura
          </Button>
        </Flex>
      </Card>
      <AddBilling
        concepts={
          editingInvoice ? editingInvoiceConcepts : billingCompanyConcepts
        }
        editingInvoice={editingInvoice}
        callback={onCreateInvoice}
      />
    </>
  );
};

const mapStateToProps = (state: {
  catalogs: CatalogsState;
  payingCompanies: PayingCompaniesState;
}): {
  catalogsState: CatalogsState;
  payingCompaniesState: PayingCompaniesState;
} => {
  return {
    catalogsState: state.catalogs,
    payingCompaniesState: state.payingCompanies,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    modal: bindActionCreators(
      {
        open: openModal,
        close: closeModal,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
