export interface PayrollScheme {
  value: string;
  label: string;
}

export const payrollSchemes: PayrollScheme[] = [
  {
    value: 'SYS + PPPS',
    label: 'Mixto (SYS+NOM o SYS+PPP)',
  },
  {
    value: 'Finiquito',
    label: 'Finiquito',
  },
  {
    value: 'Sindicatos NOM 035',
    label: 'Sindicatos NOM 035',
  },
  {
    value: 'PPPS',
    label: 'Solo NOM. 035',
  },
  {
    value: 'Tradicional',
    label: 'Tradicional (SYS)',
  },
];

export interface FrequencyType {
  label: string;
  value: number;
}

enum Frequency {
  LUNES = 1,
  MARTES = 2,
  MIERCOLES = 3,
  JUEVES = 4,
  VIERNES = 5,
  SABADO = 6,
  DOMINGO = 7,
}

export const frequencyTypes: FrequencyType[] = [
  { label: 'LUN - DOM', value: Frequency.LUNES },
  { label: 'MAR - LUN', value: Frequency.MARTES },
  { label: 'MIE - MAR', value: Frequency.MIERCOLES },
  { label: 'JUE - MIE', value: Frequency.JUEVES },
  { label: 'VIE - JUE', value: Frequency.VIERNES },
  { label: 'SAB - VIE', value: Frequency.SABADO },
  { label: 'DOM - SAB', value: Frequency.DOMINGO },
];

export interface PayerType {
  label: string;
  value: number;
}

enum Payer {
  Cliente = 0,
  Empleado = 1,
}

export const payerTypes: PayerType[] = [
  {
    value: Payer.Cliente,
    label: 'Cliente',
  },
  {
    value: Payer.Empleado,
    label: 'Empleado',
  },
];
