import React from 'react';
import moment from 'moment';
import { Form, notification } from 'antd';
import _ from 'lodash';
import axios from 'axios';
import { StarFilled } from '@ant-design/icons';

import { env, getPath } from 'configs/EnvironmentConfig';
import { Payroll } from 'interfaces';
import { getToken } from 'utils/axios-request';

const colors = [
  '#e57373',
  '#ba68c8',
  '#7986cb',
  '#4fc3f7',
  '#4db6ac',
  '#aed581',
  '#fff176',
  '#ffb74d',
  '#ff8a65',
];

const RESOURCES = {
  SEARCH_PAYROLLS: getPath('payrolls/search', 'searchPayrolls'),
};

const getPayrollIcon = ({
  fontSize,
  iconColor,
}: {
  fontSize: string;
  iconColor: string;
}) => <StarFilled style={{ fontSize, color: iconColor }} />;

const getPayrollFlags = (payroll: Payroll | undefined) => {
  return {
    isPensionBase: payroll?.taxSchema === 'SYS + PPPS',
    isHighDirection: payroll?.is_high_direction,
    isSuperPayroll: payroll?.is_super_payroll ? true : false,
    hasSYSNamespace: /(SYS|Serv|Trad)/i.test(payroll?.taxSchema || ''),
  };
};

export const getPayrolls = (clientId: string) =>
  axios
    .get(`${env?.API_ENDPOINT_URL}/${RESOURCES.SEARCH_PAYROLLS}`, {
      headers: { ...getToken() },
      params: { clientId },
    })
    .then((resp) => resp.data);

const parsePayroll = (payroll: Payroll): Payroll => {
  const { contract_start_at, contract_end_at, operational_contacts } = payroll;

  return {
    ...payroll,
    operationalContactsOptions: { ...operational_contacts }, // solo para tener las opciones en los select
    contract_start_at: contract_start_at
      ? moment(contract_start_at)
      : contract_start_at,
    contract_end_at: contract_end_at
      ? moment(contract_end_at)
      : contract_end_at,
  };
};

interface MenuItem {
  label: string;
  key: string;
  icon: false | JSX.Element;
  payroll: Payroll;
}

const usePayroll = () => {
  const [payrollForm] = Form.useForm();

  const [payrolls, setPayrolls] = React.useState<Payroll[]>([]);
  const [selectedPayroll, setSelectedPayroll] = React.useState<Payroll>();
  const [isLoadingPayrolls, setIsLoadingPayrolls] = React.useState(true);

  const fetchPayrolls = React.useCallback(
    async (id: string) => {
      setIsLoadingPayrolls(true);
      try {
        const payrollsResp = await getPayrolls(id);
        const payrollsHits = payrollsResp?.hits.map(
          (i: any) => i?.document?.raw
        ) as Payroll[];

        if (!payrollsHits?.length) return;

        const defaultSelectedPayroll = parsePayroll(payrollsHits[0]);

        setPayrolls(payrollsHits);
        setSelectedPayroll(defaultSelectedPayroll);

        payrollForm.setFieldsValue(defaultSelectedPayroll);
      } catch (error: any) {
        notification.error({
          message: 'Error al obtener ClientPayrolls',
          description: error?.message || 'Error desconocido',
        });
      } finally {
        setIsLoadingPayrolls(false);
      }
    },
    [payrollForm]
  );

  const menuItems = payrolls.reduce(
    (accumulator: MenuItem[], payroll: Payroll, index: number) => {
      const iconColor = colors[index % colors.length];

      const item = {
        key: payroll._id,
        label: `${payroll.key_str} - ${payroll.name}`,
        icon:
          payroll.is_super_payroll &&
          getPayrollIcon({ fontSize: '19px', iconColor }),
        payroll,
      };

      const childItems = payroll.superPayrollChilds.map((payroll) => ({
        key: payroll._id,
        label: `${payroll.key_str} - ${payroll.name}`,
        icon: getPayrollIcon({ fontSize: '14px', iconColor }),
        payroll,
      }));

      return [...accumulator, item, ...childItems];
    },
    []
  );

  const onSelectMenuPayroll = ({ key }: { key: string }) => {
    const payrollFound = menuItems.find((i) => i.key === key);

    if (!payrollFound) return;

    const payroll = parsePayroll(payrollFound.payroll);

    setSelectedPayroll(payroll);
    payrollForm.setFieldsValue(payroll);
  };

  const onChangeSelectedPayroll = (key: string, value: any) => {
    if (!selectedPayroll) return;

    const selectedPayrollUpdated = _.set<Payroll>(selectedPayroll, key, value);

    setSelectedPayroll((prev) => ({
      ...prev,
      ...selectedPayrollUpdated,
    }));
  };

  return {
    payrollForm,
    fetchPayrolls,
    state: {
      payrolls,
      menuItems,
      selectedPayroll,
      isLoadingPayrolls,
      menuSelected: selectedPayroll?._id ? [selectedPayroll._id] : [],
      payrollFlags: getPayrollFlags(selectedPayroll),
    },
    methods: {
      onSelectMenuPayroll,
      onChangeSelectedPayroll,
    },
  };
};

export default usePayroll;
