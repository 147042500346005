import {
  Card,
  Checkbox,
  Divider,
  Form,
  Radio,
  Spin,
  TimePicker,
  Typography,
} from 'antd';
import Flex from 'components/shared-components/Flex';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchWorkday } from 'store/httpActions';
import { ClientsState } from 'store/interfaces';

const { Title } = Typography;

interface Props {
  clientsState: ClientsState;
  fetchWorkday: (client: string) => void;
}

const dayItems = [
  {
    label: 'Lunes',
    day: 1,
  },
  {
    label: 'Martes',
    day: 2,
  },
  {
    label: 'Miercoles',
    day: 3,
  },
  {
    label: 'Jueves',
    day: 4,
  },
  {
    label: 'Viernes',
    day: 5,
  },
  {
    label: 'Sabado',
    day: 6,
  },
  {
    label: 'Domingo',
    day: 7,
  },
];

enum tipoJornada {
  DIURNA = 1,
  NOCTURNA = 2,
  MIXTA = 3,
}

const formatNumberToTime = (time: number | undefined) => {
  if (!time) return null;

  const hours = Math.floor(time / 100);
  const minutes = time % 100;

  return moment().hours(hours).minutes(minutes);
};

const Workday = ({ clientsState, fetchWorkday }: Props) => {
  const { id = '' } = useParams();

  const { workday } = clientsState;

  React.useEffect(() => {
    fetchWorkday(id);
  }, [fetchWorkday, id]);

  return (
    <div>
      <Spin spinning={workday.isLoading}>
        {workday.data.map((workday) => {
          const workTimes = workday?.work_times?.map(
            (workTime) => workTime?.day
          );

          return (
            <Card key={workday._id} className="workday">
              <Title level={3}>{workday?.name}</Title>
              <Divider />
              <div className="pb-4">
                <Title level={4}>Días</Title>
                {dayItems.map((item) => (
                  <Checkbox
                    key={item.label}
                    checked={workTimes.indexOf(item.day) >= 0}
                  >
                    {item.label}
                  </Checkbox>
                ))}
              </div>

              <div className="pb-4">
                <Title level={4} className="pt-4">
                  Tipo de jornada
                </Title>
                <Radio.Group
                  value={workday.temp_type}
                  buttonStyle="solid"
                  size="small"
                >
                  <Radio.Button value={tipoJornada.DIURNA}>Diurna</Radio.Button>
                  <Radio.Button value={tipoJornada.MIXTA}>Mixto</Radio.Button>
                  <Radio.Button value={tipoJornada.NOCTURNA}>
                    Nocturna
                  </Radio.Button>
                </Radio.Group>
              </div>

              <div className="pb-4">
                <Title level={4} className="pt-4">
                  Horario de Jornada
                </Title>
                <Flex>
                  <Form.Item label="Inicio de Jornada" className="mb-0">
                    <TimePicker
                      format="HH:mm"
                      value={formatNumberToTime(workday?.work_times[0]?.start)}
                    />
                  </Form.Item>
                  <Form.Item label="Fin de Jornada" className="pl-3 mb-0">
                    <TimePicker
                      format="HH:mm"
                      value={formatNumberToTime(workday?.work_times[0]?.end)}
                    />
                  </Form.Item>
                </Flex>
              </div>

              <div className="pb-4">
                <Title level={4} className="pt-4">
                  Horario de Comida
                </Title>
                <Flex>
                  <Form.Item label="Inicio de Jornada">
                    <TimePicker
                      format="HH:mm"
                      value={formatNumberToTime(
                        workday?.launch_times[0]?.start
                      )}
                    />
                  </Form.Item>
                  <Form.Item label="Fin de Jornada" className="pl-3">
                    <TimePicker
                      format="HH:mm"
                      value={formatNumberToTime(workday?.launch_times[0]?.end)}
                    />
                  </Form.Item>
                </Flex>
              </div>
            </Card>
          );
        })}
        {workday.isLoading && !workday.data.length && (
          <Card style={{ minHeight: '300px' }} />
        )}
      </Spin>
    </div>
  );
};

const mapStateToProps = (state: {
  clients: ClientsState;
}): {
  clientsState: ClientsState;
} => {
  return {
    clientsState: state.clients,
  };
};

const mapDispatchToProps = {
  fetchWorkday,
};

export default connect(mapStateToProps, mapDispatchToProps)(Workday);
