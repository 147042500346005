import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Spin,
  Switch,
  Typography,
} from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import { rules, fieldsTranslation } from './kardexRules';
import { getToken } from 'utils/axios-request';

const { Title } = Typography;
const { TextArea } = Input;

const servicesTypesOptions = [
  { label: 'NOM 035 + SERV. ESP', value: 'NOM 035 + SERV. ESP' },
  { label: 'NOM 035', value: 'NOM 035' },
  { label: 'SERV. ESP', value: 'SERV. ESP' },
  { label: 'Sindicatos NOM 035', value: 'Sindicatos NOM 035' },
];

const formulaTypeOptions = [
  { label: 'Formula general', value: false },
  { label: 'Formula Fija', value: true },
];

const contractsSendOptions = [
  { label: 'Digital', value: 'digital' },
  { label: 'Impreso', value: 'impreso' },
];

const frequencyTypes = [
  { label: 'LUN - DOM', value: 'LUNES' },
  { label: 'MAR - LUN', value: 'MARTES' },
  { label: 'MIE - MAR', value: 'MIERCOLES' },
  { label: 'JUE - MIE', value: 'JUEVES' },
  { label: 'VIE - JUE', value: 'VIERNES' },
  { label: 'SAB - VIE', value: 'SABADO' },
  { label: 'DOM - SAB', value: 'DOMINGO' },
];

const Kardex = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { id } = useParams();

  const [periodFrequencies, setPeriodFrequencies] = useState([]);
  const [payingCompanies, setPayingCompanies] = useState([]);
  const [places, setPlaces] = useState([]);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [groupEntities, setGroupEntities] = useState([]);
  const [cfdis, setCFDIs] = useState([]);
  const [taxRegimes, setTaxRegimes] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentMethodTypes, setPaymentMethodTypes] = useState([]);
  const [billingConcepts, setBillingConcepts] = useState([]);

  const [periodFrequenciesMap, setPeriodFrequenciesMap] = useState({});

  const serviceType = Form.useWatch('serviceType', form);
  const frequencies = Form.useWatch('frequencies', form);

  const [showFrequenciesCard, setShowFrequenciesCard] = useState(false);

  const [warnings, setWarnings] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    (frequencies || []).forEach((id) => {
      if (periodFrequenciesMap[id].name === 'Semanal') {
        setShowFrequenciesCard(true);
        return;
      } else {
        setShowFrequenciesCard(false);
      }
    });
  }, [frequencies, periodFrequenciesMap]);

  const periodFrequenciesOptions = useMemo(() => {
    return periodFrequencies.map((el) => {
      return {
        label: el.name,
        value: el._id,
      };
    });
  }, [periodFrequencies]);

  const payingCompaniesOptions = useMemo(() => {
    return payingCompanies.map((el) => {
      return {
        label: el.name,
        value: el._id,
      };
    });
  }, [payingCompanies]);

  const placesOptions = useMemo(() => {
    return places.map((el) => {
      return {
        label: el.name,
        value: el._id,
      };
    });
  }, [places]);

  const bankAccountsOptions = useMemo(() => {
    const payingCompaniesMapped = _.keyBy(payingCompanies, '_id');
    return bankAccounts.map((el) => {
      return {
        label: `[${payingCompaniesMapped[el.society].key}] ${
          el.bank
        } - Cuenta: ${el.account_number}`,
        value: el._id,
      };
    });
  }, [bankAccounts, payingCompanies]);

  const groupEntitiesOptions = useMemo(() => {
    return groupEntities.map((el) => {
      return {
        label: el.name,
        value: el._id,
      };
    });
  }, [groupEntities]);

  const cfdisOptions = useMemo(() => {
    return cfdis.map((el) => {
      return {
        label: `(${el.c_UsoCFDI}) ${el.description}`,
        value: el._id,
      };
    });
  }, [cfdis]);

  const taxRegimesOptions = useMemo(() => {
    return taxRegimes.map((el) => {
      return {
        label: `(${el.c_RegimenFiscal}) ${el.description}`,
        value: el._id,
      };
    });
  }, [taxRegimes]);

  const paymentMethodsOptions = useMemo(() => {
    return paymentMethods.map((el) => {
      return {
        label: `(${el.c_MetodoPago}) ${el.description}`,
        value: el._id,
      };
    });
  }, [paymentMethods]);

  const paymentMethodTypesOptions = useMemo(() => {
    return paymentMethodTypes.map((el) => {
      return {
        label: `(${el.c_FormaPago}) ${el.name}`,
        value: el._id,
      };
    });
  }, [paymentMethodTypes]);

  const billingConceptsOptions = useMemo(() => {
    return billingConcepts.map((el) => {
      return {
        label: el.name,
        value: el._id,
      };
    });
  }, [billingConcepts]);

  const getData = async () => {
    try {
      setLoading(true);
      const [
        clientResponse,
        periodFrequenciesResponse,
        payingCompaniesResponse,
        placesResponse,
        groupEntitiesResponse,
        cfdisResponse,
        taxRegimesResponse,
        paymentMethodsResponse,
        paymentMethodTypesResponse,
        billingConceptResponse,
      ] = await Promise.all([
        axios.get(`https://mini.levita.app/api/client?id=${id}`, {
          headers: { ...getToken() },
        }),
        axios.get('https://mini.levita.app/api/payrollFrequencies', {
          headers: { ...getToken() },
        }),
        axios.get('https://mini.levita.app/api/payingCompanies', {
          headers: { ...getToken() },
        }),
        axios.get('https://mini.levita.app/api/places', {
          headers: { ...getToken() },
        }),
        axios.get('https://mini.levita.app/api/groupEntities', {
          headers: { ...getToken() },
        }),
        axios.get('https://mini.levita.app/api/cfdis', {
          headers: { ...getToken() },
        }),
        axios.get('https://mini.levita.app/api/taxRegimes', {
          headers: { ...getToken() },
        }),
        axios.get('https://mini.levita.app/api/paymentMethods', {
          headers: { ...getToken() },
        }),
        axios.get('https://mini.levita.app/api/paymentMethodTypes', {
          headers: { ...getToken() },
        }),
        axios.get('https://mini.levita.app/api/billingConcepts', {
          headers: { ...getToken() },
        }),
      ]);

      const clientData = clientResponse?.data?.[0];
      const periodFrequenciesData = periodFrequenciesResponse?.data || [];
      const payingCompaniesData = payingCompaniesResponse?.data || [];
      const placesData = placesResponse?.data || [];
      const groupEntitiesData = groupEntitiesResponse?.data || [];
      const cfdisData = cfdisResponse?.data || [];
      const taxRegimesData = taxRegimesResponse?.data || [];
      const paymentMethodsData = paymentMethodsResponse?.data || [];
      const paymentMethodTypesData = paymentMethodTypesResponse?.data || [];
      const billingConceptsData = billingConceptResponse?.data || [];

      setPeriodFrequencies(periodFrequenciesData);
      setPayingCompanies(payingCompaniesData);
      setPlaces(placesData);
      setGroupEntities(groupEntitiesData);
      setCFDIs(cfdisData);
      setTaxRegimes(taxRegimesData);
      setPaymentMethods(paymentMethodsData);
      setPaymentMethodTypes(paymentMethodTypesData);
      setBillingConcepts(billingConceptsData);

      const bankAccountsResponse = await axios.post(
        `https://mini.levita.app/api/bankAccounts`,
        {
          societies: clientData.payingCompanies,
        }
      );
      const bankAccountsData = bankAccountsResponse?.data || [];
      setBankAccounts(bankAccountsData);

      const periodFrequenciesMapData = _.keyBy(periodFrequenciesData, '_id');
      setPeriodFrequenciesMap(periodFrequenciesMapData);

      form.setFieldsValue({
        businessName: clientData?.general?.businessName,
        tradeName: clientData?.general?.trade_name,
        code: clientData?.code_str,
        industry: clientData?.general?.industry,
        workAddress: clientData?.general?.work_address,
        zipCode: clientData?.general?.zipCode,
        rfc: clientData?.general?.rfc,
        legalRepresentative: clientData?.kardex?.legalRepresentative,
        employerRegistration: clientData?.kardex?.employerRegistration,
        serviceType: clientData?.service_type,
        formulaType: clientData?.kardex?.payment?.formula_fija,
        frequencies: clientData?.payment?.frequencies,
        frequencyTypes: clientData?.payment?.frequency_types || [],
        payingCompanies: clientData?.payingCompanies,
        plaza: clientData?.place,
        risks: clientData?.validations?.risk_classes,
        seller: clientData?.kardex?.seller,
        sellerComission: clientData?.kardex?.payment?.comission,
        numberOfEmployees: clientData?.basic?.numberOfEmployees,
        potentialEmployees: clientData?.kardex?.potential_employees,
        dischargeDate: moment(clientData?.general?.dischargeDate),
        chargeDate: moment(clientData?.general?.chargeDate),
        IMSSChargeDate: moment(clientData?.general?.IMSSChargeDate),
        employeesTransitionCommitmentDate: moment(
          clientData?.kardex?.operation?.employees_transition_commitment_date
        ),
        collectingContractsCommitmentDate: moment(
          clientData?.kardex?.operation?.collecting_contracts_commitment_date
        ),
        contract: clientData?.kardex?.documentation?.contract,
        caseFile: clientData?.kardex?.documentation?.caseFile,
        employeeContracts: clientData?.kardex?.documentation?.employeeContracts,
        collaboratorsDocumentation:
          clientData?.kardex?.documentation?.collaborators_documentation,
        eba: clientData?.kardex?.documentation?.eba,
        ema: clientData?.kardex?.documentation?.ema,
        documentsDeliveryCommitmentDate: moment(
          clientData?.kardex?.operation?.documents_delivery_commitment_date
        ),
        registrationWork: clientData?.kardex?.operation?.registration_work,
        operativeContactName: clientData?.contacts?.[0]?.name,
        operativeContactEmail: clientData?.contacts?.[0]?.email,
        operativeContactAddress: clientData?.contacts?.[0]?.address,
        oprtativeContactPhone: clientData?.contacts?.[0]?.phone,
        operativeContactSchedule: clientData?.contacts?.[0]?.schedule,
        bankAccountsToDepositPayroll:
          clientData?.payment?.bank_accounts_to_deposit_payroll,
        bankAccountsForPayrollDispersal:
          clientData?.payment?.bank_accounts_for_payroll_dispersal,
        employeeBanks: clientData?.payment?.employee_banks,
        employeeCardsBank: clientData?.payment?.employee_cards_bank,
        bankMigration: clientData?.kardex?.payment?.bank_migration,
        isnStates: clientData?.kardex?.isn_information?.states,
        supportWithPaperwork:
          clientData?.kardex?.isn_information?.supportWithPaperwork,
        personWhoSupports:
          clientData?.kardex?.isn_information?.person_who_supports,
        groupEntity: clientData?.group_entity,
        lowestWagePermitted: clientData?.kardex?.payment?.lowest_wage_permitted,
        isBorderArea: clientData?.is_border_area,
        dateFirstDisperssion: moment(
          clientData?.kardex?.payment?.dateFirstDisperssion
        ),
        periodCutOffDay: clientData?.kardex?.service?.period_cut_day,
        isnCommitmentDate: moment(
          clientData?.kardex?.operation?.isn_commitment_date
        ),
        observations: clientData?.kardex?.acceptance?.observations,
        whoAbsorbsISR: clientData?.kardex?.service?.who_absorbs_isr,
        nom035Agreement: clientData?.kardex?.service?.nom035_agreement,
        fiscalRegime: clientData?.kardex?.billing?.fiscal_regime,
        useCFDI: clientData?.kardex?.billing?.use_cfdi,
        paymentMethod: clientData?.kardex?.billing?.payment_method,
        paymentMethodType: clientData?.kardex?.billing?.payment_method_type,
        billingConcept: clientData?.kardex?.billing?.billing_concept,
        satConceptKey: clientData?.kardex?.sat_concept_key,
        services: clientData?.kardex?.service?.services,
        coverageStartDate: moment(clientData?.insurance?.coverage_start_date),
        chargeStartDate: moment(clientData?.insurance?.charge_start_date),
      });
    } catch (err) {
      // Handle errors here
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const onClickSubmitButton = async () => {
    form
      .validateFields()
      .then((values) => {
        console.log(values, 'values');
      })
      .catch((errors) => {
        const warnings = errors.errorFields.map((fields) => {
          return {
            name: fields.name[0],
            errors: fields.errors,
          };
        });
        setWarnings(warnings);
      })
      .finally(() => {
        setModalOpen(true);
      });
  };

  return (
    <>
      <Spin spinning={loading}>
        <Form form={form} layout="vertical">
          <Card>
            <div className="text-right mb-3">
              <Button
                onClick={onClickSubmitButton}
                type="primary"
                icon={<SaveOutlined />}
                shape="circle"
                size="small"
              />
            </div>
            <Title>General</Title>
            <Form.Item
              label="Razon social del cliente"
              name="businessName"
              rules={rules.businessName}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Nombre comercial"
              name="tradeName"
              rules={rules.tradeName}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Codigo de referencia"
              name="code"
              rules={rules.code}
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              label="Giro de la empresa"
              name="industry"
              rules={rules.industry}
            >
              <Input />
            </Form.Item>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label="Direccion"
                  name="workAddress"
                  rules={rules.workAddress}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Codigo postal"
                  name="zipCode"
                  rules={rules.zipCode}
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={serviceType !== 'NOM 035' ? 12 : 8}>
                <Form.Item label="RFC" name="rfc" rules={rules.rfc}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8} hidden={serviceType !== 'NOM 035'}>
                <Form.Item
                  label="Representate legal"
                  name="legalRepresentative"
                  rules={rules.legalRepresentative}
                >
                  <Input style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={serviceType !== 'NOM 035' ? 12 : 8}>
                <Form.Item
                  label="Registro patronal"
                  name="employerRegistration"
                  rules={rules.employerRegistration}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label="Tipo de servicio"
              name="serviceType"
              rules={rules.serviceType}
            >
              <Select options={servicesTypesOptions} />
            </Form.Item>
            <Form.Item
              label="Tipo de formula"
              name="formulaType"
              hidden={serviceType === 'NOM 035'}
              rules={rules.formulaType}
            >
              <Select options={formulaTypeOptions} />
            </Form.Item>
          </Card>
          <Card>
            <Title>Periodos de pago</Title>
            <Form.Item
              label="Selecciona los periodos"
              name="frequencies"
              rules={rules.frequencies}
            >
              <Select options={periodFrequenciesOptions} mode="multiple" />
            </Form.Item>
          </Card>
          <Card hidden={!showFrequenciesCard}>
            <Title>Frecuencia</Title>
            <Form.Item
              label="Selecciona la frecuencia"
              name="frequencyTypes"
              rules={rules.frequencyTypes}
            >
              <Select options={frequencyTypes} mode="multiple" />
            </Form.Item>
          </Card>
          <Card>
            <Title>Empresa</Title>
            <Form.Item
              label="Selecciona las empresas"
              name="payingCompanies"
              rules={rules.payingCompanies}
            >
              <Select
                options={payingCompaniesOptions}
                mode="multiple"
                showSearch
              />
            </Form.Item>
            <Form.Item label="Plaza" name="plaza" rules={rules.plaza}>
              <Select options={placesOptions} />
            </Form.Item>
            <Form.Item
              label="Riesgo"
              name="risks"
              hidden={serviceType === 'NOM 035'}
              rules={rules.risks}
            >
              <Select options={[]} mode="multiple" />
            </Form.Item>
          </Card>
          <Card>
            <Title>Vendedor</Title>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item label="Vendedor" name="seller" rules={rules.seller}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="% de comision negociada con el cliente"
                  name="sellerComission"
                  rules={rules.sellerComission}
                >
                  <InputNumber prefix="%" style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Card>
            <Title>Bancos</Title>
            <Form.Item
              label="Cuentas donde el cliente pagara la nomina"
              name="bankAccountsToDepositPayroll"
              rules={rules.bankAccountsToDepositPayroll}
            >
              <Select options={bankAccountsOptions} mode="multiple" />
            </Form.Item>
            <Form.Item
              label="Cuentas que se utilizaran para dispersar"
              name="bankAccountsForPayrollDispersal"
              rules={rules.bankAccountsForPayrollDispersal}
            >
              <Select options={bankAccountsOptions} mode="multiple" />
            </Form.Item>
            <Form.Item
              label="Bancos que manejan los colaboradores"
              name="employeeBanks"
              rules={rules.employeeBanks}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Escribe los banco(s) si se proporcionaran tarjetas"
              name="employeeCardsBank"
              hidden={serviceType === 'NOM 035'}
              rules={rules.employeeCardsBank}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Se realizara migracion de banco?"
              valuePropName="checked"
              name="bankMigration"
              rules={rules.bankMigration}
            >
              <Switch />
            </Form.Item>
          </Card>
          <Card>
            <Title>Empleados</Title>
            <Row gutter={12}>
              <Col span={serviceType === 'NOM 035' ? 24 : 12}>
                <Form.Item
                  label="Numero de empleados"
                  name="numberOfEmployees"
                  rules={rules.numberOfEmployees}
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={12} hidden={serviceType === 'NOM 035'}>
                <Form.Item
                  label="Potencial de crecimiento"
                  name="potentialEmployees"
                  rules={rules.potentialEmployees}
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label="Fecha de baja de la empresa anterior"
                  name="dischargeDate"
                  hidden={serviceType === 'NOM 035'}
                  rules={rules.dischargeDate}
                >
                  <DatePicker style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={serviceType === 'NOM 035' ? 24 : 12}>
                <Form.Item
                  label="Fecha de alta en el sistema INTELA"
                  name="chargeDate"
                  rules={rules.chargeDate}
                >
                  <DatePicker style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12} hidden={serviceType === 'NOM 035'}>
              <Col span={12}>
                <Form.Item
                  label="Fecha de alta en el IMSS"
                  name="IMSSChargeDate"
                  rules={rules.IMSSChargeDate}
                >
                  <DatePicker style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Fecha compromiso para transicion de empleados"
                  name="employeesTransitionCommitmentDate"
                  rules={rules.employeesTransitionCommitmentDate}
                >
                  <DatePicker style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12} hidden={serviceType === 'NOM 035'}>
              <Col span={12}>
                <Form.Item
                  label="Fecha compromiso recoleccion de contratos"
                  name="collectingContractsCommitmentDate"
                  rules={rules.collectingContractsCommitmentDate}
                >
                  <DatePicker style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Envio de contratos"
                  name="commitmentDates"
                  rules={rules.commitmentDates}
                >
                  <Checkbox.Group options={contractsSendOptions} />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Card>
            <Title>Servicios contratados</Title>
            <Form.Item
              label="Selecciona los servicios contratados"
              name="services"
              rules={rules.services}
            >
              <Select mode="multiple" options={[]} />
            </Form.Item>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label="Fecha de inicio cobertura"
                  name="coverageStartDate"
                  rules={rules.coverageStartDate}
                >
                  <DatePicker style={{ width: '100% ' }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Fecha de inicio cobro"
                  name="chargeStartDate"
                  rules={rules.chargeStartDate}
                >
                  <DatePicker style={{ width: '100% ' }} />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Card hidden={serviceType === 'NOM 035'}>
            <Title>Prestaciones adicionales</Title>
          </Card>
          <Card>
            <Title>Expediente cliente</Title>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label="Contrato de servicio firmado"
                  name="contract"
                  valuePropName="checked"
                  rules={rules.contract}
                >
                  <Switch />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Documentación empresa"
                  valuePropName="checked"
                  name="caseFile"
                  rules={rules.caseFile}
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label="Contrato individual de contratos firmados"
                  name="employeeContracts"
                  valuePropName="checked"
                  rules={rules.employeeContracts}
                >
                  <Switch />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Documentación colaboradores"
                  name="collaboratorsDocumentation"
                  valuePropName="checked"
                  rules={rules.collaboratorsDocumentation}
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label="EBA proporcionado"
                  name="eba"
                  valuePropName="checked"
                  rules={rules.eba}
                >
                  <Switch />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="EMA proporcionado"
                  name="ema"
                  valuePropName="checked"
                  rules={rules.ema}
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label="Fecha compromiso de entrega de expedientes completos"
                  name="documentsDeliveryCommitmentDate"
                  rules={rules.documentsDeliveryCommitmentDate}
                >
                  <DatePicker style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Registro de la obra proporcionado"
                  name="registrationWork"
                  rules={rules.registrationWork}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Card>
            <Title>Contacto operativo</Title>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label="Persona de contacto operativo"
                  name="operativeContactName"
                  rules={rules.operativeContactName}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Email"
                  name="operativeContactEmail"
                  rules={rules.operativeContactEmail}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label="Domicilio"
              name="operativeContactAddress"
              rules={rules.operativeContactAddress}
            >
              <Input />
            </Form.Item>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label="Telefono"
                  name="oprtativeContactPhone"
                  rules={rules.oprtativeContactPhone}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12} hidden={serviceType === 'NOM 035'}>
                <Form.Item
                  label="Horario de atencion"
                  name="operativeContactSchedule"
                  rules={rules.operativeContactSchedule}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Card>
            <Title>ISN</Title>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label="Selecciona los estados"
                  name="isnStates"
                  rules={rules.isnStates}
                >
                  <Select options={[]} mode="multiple" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="% retencion"
                  name="isnRetention"
                  rules={rules.isnRetention}
                >
                  <InputNumber prefix="%" style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label="Apoyo con tramite de ISN?"
                  name="supportWithPaperwork"
                  valuePropName="checked"
                  rules={rules.supportWithPaperwork}
                >
                  <Switch />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Quien apoyara tramite?"
                  name="personWhoSupports"
                  rules={rules.personWhoSupports}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Card>
            <Title>Caracteristicas especificas del servicio</Title>
            <Form.Item
              label="Grupo de cliente"
              name="groupEntity"
              rules={rules.groupEntity}
            >
              <Select options={groupEntitiesOptions} />
            </Form.Item>
            <Row gutter={12} hidden={serviceType === 'NOM 035'}>
              <Col span={8}>
                <Form.Item
                  label="Salario diario minimo autorizado"
                  name="lowestWagePermitted"
                  rules={rules.lowestWagePermitted}
                >
                  <InputNumber style={{ width: '100%' }} prefix="$" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Veces de salario minimo autorizado">
                  <InputNumber style={{ width: '100%' }} prefix="$" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Zona economica"
                  name="isBorderArea"
                  rules={rules.isBorderArea}
                >
                  <Radio.Group>
                    <Radio value={true}>Fronteriza</Radio>
                    <Radio value={false}>No fronteriza</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label="Fecha de primera dispersion"
              name="dateFirstDisperssion"
              rules={rules.dateFirstDisperssion}
            >
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              label="Corte de periodo (1 - 31)"
              name="periodCutOffDay"
              hidden={serviceType === 'NOM 035'}
              rules={rules.periodCutOffDay}
            >
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              label="Fecha compromiso para realizar el ISN"
              name="isnCommitmentDate"
              hidden={serviceType === 'NOM 035'}
              rules={rules.isnCommitmentDate}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item label="Acuerdos especiales" name="observations">
              <TextArea />
            </Form.Item>
            <Form.Item
              label="Quien absorbe el ISR?"
              name="whoAbsorbsISR"
              hidden={serviceType === 'NOM 035'}
              rules={rules.whoAbsorbsISR}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Acuerdos para encuestas NOM-035"
              name="nom035Agreement"
              rules={rules.nom035Agreement}
            >
              <Input />
            </Form.Item>
          </Card>
          <Card>
            <Title>Facturacion</Title>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label="Regimen fiscal"
                  name="fiscalRegime"
                  rules={rules.fiscalRegime}
                >
                  <Select options={taxRegimesOptions} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Uso del CFDI"
                  name="useCFDI"
                  rules={rules.useCFDI}
                >
                  <Select options={cfdisOptions} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label="Metodo de pago"
                  name="paymentMethod"
                  rules={rules.paymentMethod}
                >
                  <Select options={paymentMethodsOptions} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Forma de pago"
                  name="paymentMethodType"
                  rules={rules.paymentMethodType}
                >
                  <Select options={paymentMethodTypesOptions} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label="Concepto de facturacion"
                  name="billingConcept"
                  rules={rules.billingConcept}
                >
                  <Select options={billingConceptsOptions} mode="multiple" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Clave de concepto"
                  name="satConceptKey"
                  rules={rules.satConceptKey}
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Card>
            <Title>Responsables</Title>
            <Form.Item label="Responsable de soporte interno">
              <Select options={[]} />
            </Form.Item>
          </Card>
        </Form>
      </Spin>
      <Drawer
        title="Campos faltantes"
        placement="right"
        onClose={() => setModalOpen(false)}
        open={!!modalOpen}
      >
        {Object.values(warnings).map((warning, idx) => {
          return (
            <div key={`${warning.name}-${idx}`}>
              <Divider>{fieldsTranslation[warning.name]}</Divider>
              {warning.errors.map((error, idx2) => {
                return <p key={`${error}- ${idx2}`}>{error}</p>;
              })}
            </div>
          );
        })}
      </Drawer>
    </>
  );
};

export default Kardex;
