import { createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { ClientsState } from './definitions';
import {
  fetchClient,
  fetchGroupEntities,
  fetchWorkday,
  fetchWorks,
} from './httpActions';

const initialState: ClientsState = {
  client: {
    data: null,
    isLoading: false,
  },
  groupEntities: {
    isLoading: false,
    data: [],
  },
  works: {
    data: [],
    isLoading: false,
  },
  workday: {
    data: [],
    isLoading: false,
  },
};

export const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGroupEntities.pending, (state) => {
        state.groupEntities.isLoading = true;
      })
      .addCase(fetchGroupEntities.fulfilled, (state, action) => {
        state.groupEntities.isLoading = false;
        state.groupEntities.data = action.payload;
      })
      .addCase(fetchGroupEntities.rejected, (state, action) => {
        state.groupEntities.isLoading = false;
        notification.error({
          message: 'Error al obtener GroupEntities',
          description: action?.error?.message || 'Error desconocido',
        });
      })
      .addCase(fetchWorks.pending, (state) => {
        state.works.isLoading = true;
      })
      .addCase(fetchWorks.fulfilled, (state, action) => {
        state.works.isLoading = false;
        state.works.data = action.payload;
      })
      .addCase(fetchWorks.rejected, (state, action) => {
        state.works.isLoading = false;
        notification.error({
          message: 'Error al obtener Works',
          description: action?.error?.message || 'Error desconocido',
        });
      })
      .addCase(fetchClient.pending, (state) => {
        state.client.isLoading = true;
      })
      .addCase(fetchClient.fulfilled, (state, action) => {
        state.client.isLoading = false;
        state.client.data = action.payload;
      })
      .addCase(fetchClient.rejected, (state, action) => {
        state.client.isLoading = false;
        notification.error({
          message: 'Error al obtener Works',
          description: action?.error?.message || 'Error desconocido',
        });
      })
      .addCase(fetchWorkday.pending, (state) => {
        state.workday.isLoading = true;
      })
      .addCase(fetchWorkday.fulfilled, (state, action) => {
        state.workday.isLoading = false;
        state.workday.data = action.payload;
      })
      .addCase(fetchWorkday.rejected, (state, action) => {
        state.workday.isLoading = false;
        notification.error({
          message: 'Error al obtener Workday',
          description: action?.error?.message || 'Error desconocido',
        });
      });
  },
});

export default clientsSlice.reducer;
