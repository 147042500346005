import { Form, Switch } from 'antd';
import React from 'react';

const Documentation = () => {
  return (
    <div className="py-4">
      <Form.Item
        label="Documentación"
        valuePropName="checked"
        name={['kardex', 'documentation', 'caseFile']}
      >
        <Switch />
      </Form.Item>
      <Form.Item
        label="Contrato"
        valuePropName="checked"
        name={['kardex', 'documentation', 'contract']}
      >
        <Switch />
      </Form.Item>
      <Form.Item
        label="EBA"
        valuePropName="checked"
        name={['kardex', 'documentation', 'eba']}
      >
        <Switch />
      </Form.Item>
      <Form.Item
        label="EMA"
        valuePropName="checked"
        name={['kardex', 'documentation', 'ema']}
      >
        <Switch />
      </Form.Item>
      <Form.Item
        label="Contratos de empleado"
        valuePropName="checked"
        name={['kardex', 'documentation', 'employeeContracts']}
      >
        <Switch />
      </Form.Item>
      <Form.Item
        label="Documentos Subidos"
        valuePropName="checked"
        name={['kardex', 'documentation', 'static']}
      >
        <Switch />
      </Form.Item>
    </div>
  );
};

export default Documentation;
