import { notification } from 'antd';
import axios from 'axios';

export const Alert = {
  error: (error: unknown, message: string) => {
    let errorMessage = '';

    if (axios.isAxiosError(error)) {
      errorMessage = error?.response?.data || error?.message;
    } else if (error instanceof Error) {
      errorMessage = error?.message;
    } else {
      errorMessage = 'Ocurrió un error desconocido';
    }

    notification.error({
      message,
      description:
        typeof errorMessage === 'string'
          ? errorMessage
          : JSON.stringify(errorMessage),
    });
  },
};
