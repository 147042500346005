import { Card, Checkbox, Form, Spin, Typography, notification } from 'antd';
import axios from 'axios';
import { env, getPath } from 'configs/EnvironmentConfig';
import { Payroll, PerceptionModifier } from 'interfaces';
import React from 'react';
import { connect } from 'react-redux';
import { ClientsState } from 'store/interfaces';
import { CatalogsState } from 'store/slices/catalogs';

const { Title } = Typography;

const RESOURCES = {
  PERCEPTION_MODIFIER: getPath('perceptionModifier', 'perceptionModifier'),
};

export const getPerceptionModifiers = (payrollType: string) =>
  axios
    .get<PerceptionModifier[]>(
      `${env?.API_ENDPOINT_URL}/${RESOURCES.PERCEPTION_MODIFIER}`,
      {
        params: { payrollType },
      }
    )
    .then((resp) => resp.data);

export enum ModifierTypeEnum {
  Seguros = 1,
  Financieros = 2,
  Adicionales = 3,
  Ajuste = 4,
}

interface Props {
  selectedPayroll: undefined | Payroll;
  clientsState: ClientsState;
  catalogsState: CatalogsState;
}

const PerceptionModifiers = ({
  selectedPayroll,
  clientsState,
  catalogsState,
}: Props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [payrollPerceptionModifiers, setPayrollPerceptionModifiers] =
    React.useState<PerceptionModifier[]>([]);

  const { client } = clientsState;
  const { modifierTypes } = catalogsState;

  const clientModifierTypesId = client.data?.modifier_types_id || []; // configurados
  const clientModifierTypes = client.data?.modifier_types || []; // seleccionados

  // filter valid clientModifierTypesId in db
  const validModifierTypesIds = modifierTypes.data
    .filter((i) => i._id && clientModifierTypesId.includes(i._id))
    .map((i) => i._id);

  // filter selected clientModifierTypes in clientModifierTypesId configured
  const validModifierTypes = validModifierTypesIds.filter((i) =>
    clientModifierTypes.includes(i)
  );

  const perceptionModifiersConfigured = payrollPerceptionModifiers.filter((i) =>
    validModifierTypes.includes(i.modifier_type)
  );

  const fetchPerceptionModifiers = React.useCallback(async () => {
    if (!selectedPayroll?._id) return;

    setIsLoading(true);
    try {
      const resp = await getPerceptionModifiers(selectedPayroll._id);

      setPayrollPerceptionModifiers(resp);
    } catch (error: any) {
      notification.error({
        message: 'Error al obtener PerceptionModifiers',
        description: error?.message || 'Error desconocido',
      });
    } finally {
      setIsLoading(false);
    }
  }, [selectedPayroll?._id]);

  React.useEffect(() => {
    fetchPerceptionModifiers();
  }, [fetchPerceptionModifiers]);

  return (
    <Card>
      <Title level={3} className="pb-2">
        Modificadores de percepción
      </Title>
      <Spin spinning={isLoading || modifierTypes.isLoading}>
        <Title level={4}>Seguros</Title>
        <RenderModifiers
          modifiers={perceptionModifiersConfigured}
          type={ModifierTypeEnum.Seguros}
        />
        <Title level={4}>Financieros</Title>
        <RenderModifiers
          modifiers={perceptionModifiersConfigured}
          type={ModifierTypeEnum.Financieros}
        />
        <Title level={4}>Adicionales</Title>
        <RenderModifiers
          modifiers={perceptionModifiersConfigured}
          type={ModifierTypeEnum.Adicionales}
        />
        <Title level={4}>Ajuste</Title>
        {/* infonavit y fonacot vienen configurados por default */}
        <RenderModifiers
          modifiers={payrollPerceptionModifiers}
          type={ModifierTypeEnum.Ajuste}
        />
      </Spin>
    </Card>
  );
};

const RenderModifiers = ({
  modifiers,
  type,
}: {
  modifiers: PerceptionModifier[];
  type: number;
}) => {
  const filteredModifiers = modifiers.filter(
    (i) => i.modifierType.type === type
  );

  const modifierElements = filteredModifiers.map((i) => (
    <div className="pb-2" key={i.modifierType.name}>
      <Form.Item noStyle>
        <Checkbox checked={i.is_activated}>{i.modifierType.name}</Checkbox>
      </Form.Item>
    </div>
  ));

  return (
    <div className="pb-4">
      {modifierElements.length ? (
        modifierElements
      ) : (
        <p>No se encontraron modificadores de percepción</p>
      )}
    </div>
  );
};

const mapStateToProps = (state: {
  catalogs: CatalogsState;
  clients: ClientsState;
}): {
  catalogsState: CatalogsState;
  clientsState: ClientsState;
} => {
  return {
    catalogsState: state.catalogs,
    clientsState: state.clients,
  };
};

export default connect(mapStateToProps)(PerceptionModifiers);
